<!-- navigation -->
<style>
  .alert-warning {
    color: #664d03;
    background: #fff3cd;
    border-color: #ffecb5;
  }
</style>
<header class="header content-row">
  <div class="overlay_sede" id="overlay"></div>

  <img class="logo inline lg:hidden" src="./assets/images/logo-mobile.png" alt="OSI" width="164" height="33">
  <img class="logo inline hidden lg:block" src="./assets/images/logo.png" alt="OSI" width="225" height="47">

  <i style="font-size: 35px;margin-top: -35px;margin-right: 21px;" class="fa fa-clock-o float-right" aria-hidden="true"
    *ngIf="time_status"></i>

  <div class="float-right" style="margin-right: 67px;margin-top: -39px;" *ngIf="time_status">
    <h1 style="color:red;font-size: 30px;">{{counter | formatTime}}</h1>
  </div>
  <div class="alert-warning" style="text-align: center;padding: 10px;" *ngIf="estado_session_pago">
    <p>
      <strong>Alerta!</strong> Se acabo el tiempo de session para registro de citamedica.
    </p>
  </div>
  <!-- <div class="modal" id="modal_cita" *ngIf="!MedioPago">
        <div class="form-reservation__section" style="text-align: center;">
            <h3 class="form-reservation__title">Alerta</h3>
            <div class="resume__wrapper">
                <div class="resume__section">
                    <p>Ya tienes una cita reservada, para poder ayudarlo comuníquese con nuestra central telefónica.
                        (01) 7390888</p>
                </div>
            </div>
            <div class="resume__wrapper">
                <button type="button" style="display: inline;" (click)="closeModalCita()"
                    class="button bg-blue-1">Cerrar</button>
            </div>
        </div>

    </div> -->

  <!-- <div class="modal" id="modal_cita_costo_cero" *ngIf="!CostoCero">
        <div class="form-reservation__section" style="text-align: center;">
            <h3 class="form-reservation__title">Alerta</h3>
            <div class="resume__wrapper">
                <div class="resume__section">
                    <p>Usted tiene un deducible costo cero , en ese caso, para poder ayudarlo comuníquese con nuestra
                        central telefónica. (01) 7390888</p>
                </div>
            </div>
            <div class="resume__wrapper">
                <button type="button" style="display: inline;" (click)="closeModalCita()"
                    class="button bg-blue-1">Cerrar</button>
            </div>
        </div>

    </div> -->
</header>
