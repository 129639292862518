import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CitamedicaService extends ServiceBase {
    constructor(http) {
        super();
        this.http = http;
    }
    // Metodos
    turnos(params) {
        return this.getQuery('osi/citamedicarc/medico/disponibilidad', params).pipe(map(data => {
            return data.data;
        }));
    }
    CreatePagar(objeto) {
        return this.postQuery('osi/citamedica/pagarIzipay', objeto).pipe(map(data => {
            return data;
        }));
    }
    temporalPacientesParameters(objeto) {
        return this.postQuery('osi/citamedica/temporalPacientesParameters', objeto).pipe(map(data => {
            return data;
        }));
    }
    ValidarCitamedicaPortal(objeto) {
        return this.postQuery('osi/citamedica/ValidarCitamedicaPortal', objeto).pipe(map(data => {
            return data;
        }));
    }
    CreatePagarPe(objeto) {
        return this.postQuery('osi/citamedica/pagarPagoefectivo', objeto).pipe(map(data => {
            return data;
        }));
    }
    CreateCitamedicaCero(objeto) {
        return this.postQuery('osi/citamedica/reservaCitamedicaCero', objeto).pipe(map(data => {
            return data;
        }));
    }
    CreateTempCitaMedica(objeto) {
        return this.postQuery('osi/citamedicarc/tempCitamedica', objeto).pipe(map(data => {
            return data;
        }));
    }
    IpreesSited(params) {
        return this.getQuery('osi/citamedica/IpreesSitedListar').pipe(map(data => {
            return data;
        }));
    }
    disponibilidad(params) {
        return this.getQuery('osi/sede/horarios/disponibilidad', params).pipe(map(data => {
            return data;
        }));
    }
    destroyCitasTemporales(params) {
        return this.postQuery('osi/citamedica/deleteCitasTemporales', params).pipe(map(data => {
            return data;
        }));
    }
}
CitamedicaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CitamedicaService_Factory() { return new CitamedicaService(i0.ɵɵinject(i1.HttpClient)); }, token: CitamedicaService, providedIn: "root" });
