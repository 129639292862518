    <app-header></app-header>


    <!-- main content -->
    <div class="content">
        <div class="steps-wrapper">
            <div class="message">
                <div class="message__step message__step--active step-message-1">
                    <p class="font-light">Empecemos</p>
                    <h2 class="text-2xl font-medium mt-5 mb-10">Reserve una cita<br/>médica con seguro</h2>
                </div>

            </div>
            <div class="progress">
                <div class="progress__step step-1-ico progress__step--done"><span class="progess__step-text">Datos personales</span></div>
                <div class="progress__step step-2-ico progress__step--done"><span class="progess__step-text">Horarios y Doctores</span></div>
                <div class="progress__step step-3-ico progress__step--done"><span class="progess__step-text">Cuenta OSI</span></div>
                <div class="progress__step progress__step--active step-4-ico"><span class="progess__step-text">Cobertura, condiciones y pago</span></div>
                <div class="progress__step step-5-ico"><span class="progess__step-text">Resumen</span></div>
            </div>
        </div>


        <div class="form-wrapper">

            <form [formGroup]="detalleForm" (ngSubmit)="openPagar()"  >
                <div id="resume" class="form-reservation__section resume">
                    <h3 class="form-reservation__title">Detalles de la reserva</h3>

                    <div class="resume__wrapper">
                        <div class="resume__schedule md:flex">
                            <div class="resume__section md:flex-1">
                                <p>Paciente:</p>
                                <p class="font-bold">{{detalle.paciente}}</p>
                            </div>
                            <div class="resume__section md:flex-1">
                                <p>Sede:</p>
                                <p class="font-bold">{{detalle.sede}}</p>
                            </div>


                        </div>
                    </div>

                    <div class="resume__wrapper">
                        <div class="resume__schedule md:flex">
                            <div class="resume__section md:flex-1">
                                <p>Fecha de la cita médica:</p>
                                <p class="font-bold"> {{detalle.fecha | date:'EEEE, d \'de\' MMMM y' | titlecase}}</p>
                            </div>
                            <div class="resume__section md:flex-1">
                                <p>Hora de la cita médica:</p>
                                <p class="font-bold">{{detalle.hora | date:'h:mm a' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="resume__wrapper">
                        <div class="resume__section">
                            <p>Especialista que atenderá su consulta:</p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="doctor">
                            <img class="doctor__image" src="./assets/images/doctores/{{detalle.idmedico}}.jpg" onError="this.src='./assets/images/doctores/icon_doctor.jpg'" alt="Doctor 2">
                            <div class="doctor__content">
                                <p class="doctor__fullname">
                                    <span class="doctor__name">{{detalle.sexo == 'F' ? 'Dra.' :' Dr. '}} {{detalle.medico}}</span>
                                </p>
                                <p class="doctor__cmp">Especialidad: <span>   Medicina Física y Rehabilitación</span></p>

                            </div>
                        </div>
                    </div>
                    <div class="resume__wrapper">
                        <div class="resume__schedule md:flex">
                            <div class="resume__section md:flex-1">
                                <p>Seguro:</p>
                                <p class="font-bold"> {{detalle.seguro}}</p>
                            </div>
                            <div class="resume__section md:flex-1">
                                <p>Plan:</p>
                                <p class="font-bold">{{detalle.seguroplan}}</p>
                            </div>
                        </div>
                    </div>


                    <div class="resume__wrapper">
                        <div class="resume__schedule md:flex">
                            <div class="resume__section md:flex-1">
                                <p>Copago Fijo:</p>
                                <p class="font-bold"> S/. {{detalle.precioDet  }}</p>
                            </div>
                            <div class="resume__section md:flex-1">
                                <p>Copago Variable:</p>
                                <p class="font-bold">{{detalle.copagovariable}}</p>
                            </div>
                        </div>
                    </div>


                    <div class="field-group-checkbox" *ngIf="!copagoCero">
                        <input class="field-group-checkbox__field" type="checkbox" checked name="policy" id="policy">
                        <label class="field-group-checkbox__label" for="policy"> Al pagar estás aceptando nuestros <a target="_blank" class="link"
                            href="https://centromedicoosi.com/condiciones-de-atencion1.pdf">
                            Términos y Condiciones </a>.</label>
                    </div>

                    <div class="alert alert-danger animate__animated animate__headShake" role="alert"
                        *ngIf="detalleForm.invalid">
                        Debe aceptar nuestros términos y condiciones.
                    </div>
                    <hr>

                      <div class="resume__wrapper" *ngIf="!copagoCero">
                        <div class="resume__section">
                            <p>Medios de pago:</p>
                        </div>
                    </div>

                    <div class="osi-radio-group" style="justify-content:center" *ngIf="!copagoCero">
                        <!-- radio button 1 -->
                        <div class="osi-radio" style="width: 45%;" >
                            <input id="osi-radio-izipay" type="radio" formControlName="mediopago" name="mediopago"
                                value="izipay" (change)="changeMedio($event.target.value)" />
                            <label for="osi-radio-izipay" class="osi-radio--label">
                                <span class="osi-radio--button"></span>
                                <div class="alineado ancho50" style="margin-left:10px">
                                    <img src="assets/img/vinetaTarjeta.png" class="margenIzquierdo10 margenSuperior10" />
                                    </div>
                                <p>Tarjeta de Crédito o débito</p>
                            </label>
                        </div>
                        <div class="osi-radio" style="width: 45%;"  >
                            <input id="osi-radio-pagoefectivo" type="radio" formControlName="mediopago" name="mediopago"
                                value="pagoefectivo" (change)="changeMedio($event.target.value)" />
                            <label for="osi-radio-pagoefectivo" class="osi-radio--label">
                                <span class="osi-radio--button"></span>
                                <div class="alineado ancho50" style="margin-left: 10px;" >
                                    <img src="assets/img/vinetaPagoEfectivo.png" class="margenIzquierdo10 margenSuperior10" />
                                    </div>
                                <p>Pagoefectivo</p>
                            </label>
                        </div>
                    </div>

                    <div class="resume__wrapper" style="justify-content:center" *ngIf="!copagoCero">
                        <div id="button_pago">
                        </div>
                        <div>
                            <button id="btn_pagoefectivo" style="display: none;"  class="button bg-green-1 disabled:opacity-50" (click)="save_pagoefectivo()" type="button"  >Generar CIP</button>
                        </div>
                        <br>

                    </div>

                    <div class="resume__wrapper" style="justify-content:center" *ngIf="copagoCero">
                        <div>
                            <button id="btn_confirmarCita"  class="button bg-green-1 " (click)="confirmar_cita()" type="button"  >Confirmar Cita</button>
                        </div>
                        <br>
                    </div>
                </div>

                <button style="display: none;" id="button_page" type="submit"></button>
            </form>

            <input type="hidden" id='response_page' name='response_page' value="" />
            <input type="hidden" id="error_form" name="error_form" value="" />
            <button style="display: none;" ng-click="vm.error_form()" id="button_errorForm"></button>

            <a [routerLink]="'/seleccione-fecha'" class="text-body font-size-small">
                <u>Volver atrás</u>
            </a>

        </div>


    <ngx-spinner>
        <p style="color: white"> Procesando.. </p>
    </ngx-spinner>

</div>

<div class="overlay_sede" id="overlay"></div>
<div class="modal" id="modal" >
    <div  class="form-reservation__section" style="text-align: center;">
        <h3 class="form-reservation__title">Alerta</h3>
        <div class="resume__wrapper">
            <div class="resume__section">
                <p>Intente nuevamente, para poder ayudarlo comuníquese con nuestra central telefónica. (01) 7390888</p>
            </div>
        </div>
        <div class="resume__wrapper">
            <button type="button" style="display: inline;" (click)="closeModal()" class="button bg-blue-1" >Cerrar</button>
        </div>
    </div>

</div>
<br>
