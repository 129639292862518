import { Component, OnInit, NgZone } from '@angular/core';
import { CitamedicaService } from 'src/app/services/citamedica.service';
import { OsitedsService } from 'src/app/services/ositeds.service';
import { SedeService } from 'src/app/services/sede.service';
import { EntidadService } from 'src/app/services/entidad.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { Utils } from 'src/app/services/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeaderComponent } from '../../shared/header/header.component';

declare function init_appointment();
declare function selectsForms();
declare var $: any;

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styles: []
})
export class AppointmentComponent implements OnInit {

  appointmentForm: FormGroup;
  detalleForm: FormGroup;


  formNoSeguro = false;
  formSiSeguro = false;
  MedioPago = false;
  citalibre = true;

  nombre: string;
  turnos: any[] = [];
  loadTurnos = false;
  today: number = Date.now();
  errors: string = null;
  detalle: any;
  detalledatos: any;
  detallecitas: any = [];

  idsedeRes: any;

  idsedeReserva: any;

  detalleSeguro: any;
  iprees_sited: any;
  beneficio: any;
  iafas: any;
  codiafaplan: any;
  codiafaidplan: any;
  sedesdeipress: any;
  sedesdeipressTemp: any;
  sededeipress: any;

  filaSelectBusqueda: any;
  gridBusqueda: any;
  gridBeneficios: any;
  sitedsDetalle: any;
  restricciones: any;
  entVinculada: any;
  filtroTipoBusqueda: string = 'iafa';
  datosPacienteRegafi: any;
  productodetalle: any;
  ProductoSeleccionado: any;

  cobertura: any;
  status_recomen_beneficio: any;
  recomen_beneficio: any;
  active: any;
  recom:any;
  gridApiBeneficios: any;
  beneficio_sited: any;
  sededeipressCG: any;
  nomProducto_temp: any;

  oCondicionesMedicas: any;
  indExisteCondicion: any;
  informacionGeneral: any;

  num_carrera_global:any =1;
  ValidarCitas:boolean =true;

  constructor(
    private entidadService: EntidadService,
    private citamedicaService: CitamedicaService,
    private ositedsService: OsitedsService,
    private sedeService: SedeService,
    private router: Router,
    private utils: Utils,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.entidadService.reservacion.izipayButton = undefined;
    this.entidadService.saveReservacion();
    if(this.entidadService.reservacion.cita1 == 0){
      this.entidadService.reservacion.cita1 = 1;
      this.entidadService.saveReservacion();
      window.location.reload();
    }

    if (this.entidadService.reservacion.pagado){
      this.router.navigate(['/']);
    }


    init_appointment();
    selectsForms();


  }

  get f(){

    return this.detalleForm.controls;

  }
  condicionales: any[] = [
    { idcondicional: "", abreviatura: 'Seleccione' },
    { idcondicional: 2, abreviatura: 'Si' },
    { idcondicional: 1, abreviatura: 'No' }

  ];

  error_form() {
    $('#alert').empty();
    $('#alert').css('display', 'block')
    var error_form = $('#error_form').val();
    $('#alert').append('<span style="font-family: system-ui;text-align:center">' + error_form + '</span>');
    setTimeout("$('#alert').css('display','none')", 4000);
  }

  VolverAtras(){
    const datosPaciente = JSON.parse(sessionStorage.getItem('data'));
    if(datosPaciente){
      HeaderComponent.time_status_= false;
    }
    this.router.navigate(['/seleccione-sede']);
  }

  ngOnInit() {

    if (this.entidadService.reservacion.pagado === '1') {
      this.router.navigate(['/reservacion-realizada']);
    }

    this.detalleForm = new FormGroup({
      policy: new FormControl(true, [Validators.requiredTrue]),
      navegador: new FormControl(navigator.userAgent)
    });

    this.appointmentForm = this.createForm();
    this.detalleForm = new FormGroup({
      terminos: new FormControl(true, [Validators.requiredTrue]),
      mediopago: new FormControl('seleccione', Validators.required)
    });

    this.entidadService.saveReservacion();

    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (data: any) => this.indexTurnos(data),
    };

    this.spinner.show();
    setTimeout(() => {
      selectsForms();
      this.spinner.hide();
    }, 1000);

  }
  selectedseguro: String = "Seleccione";
  selectedplan: String = "Seleccione";

  seguros: Array<any> = [
		{ name: 'Rimac',  planes: [ {idplan: '1', plan: '20001', name: 'EPS' },{idplan: '2',plan: '40007', name: 'Seguros' } ] },
		{ name: 'La Positiva', planes: [ { idplan: '27',plan: '20029', name: 'EPS' },{idplan: '6',plan: '40005', name: 'Seguros' }  ] },
		{ name: 'Pacifico',  planes: [{ idplan: '9',plan: '20002', name: 'EPS' },{idplan: '10',plan: '40004', name: 'Seguros' }  ] },
		{ name: 'Mapfre',  planes: [ {idplan: '14',plan: '20004', name: 'EPS' },{idplan: '15',plan: '40006', name: 'Seguros' }  ] },
		{ name: 'Sanitas Perú', planes: [ {idplan: '19', plan: '20005', name: 'EPS' }  ] },
	];
  planes: Array<any>;

  onSelect(seguro) {
    this.entidadService.reservacion.seguro = seguro;
    this.entidadService.saveReservacion();
    this.planes = this.seguros.find(cntry => cntry.name == seguro).planes;
  }

  changeMedio(e): void {
    this.spinner.show()
    if(e == 'izipay'){
      debugger;

      this.citamedicaService.CreateTempCitaMedica({citamedica:this.entidadService.reservacion.temporalcita})
      .subscribe((data: any) => {
        this.spinner.hide();
        if (data.type === 'success') {
          this.MedioPago = true;
          this.entidadService.reservacion.citalibre = true;
          this.entidadService.saveReservacion();
        }else{
          $("#modal_cita_appoiment").addClass('is-visible')
          this.detallecitas.error="Hubo un problema con los servicios intentar nuevamente.";
          this.spinner.hide();
          this.router.navigate(['/']);
        }
      });


      if(this.entidadService.reservacion.izipayButton != 0){
        this.entidadService.reservacion.izipayButton = 1;
        this.entidadService.saveReservacion();
        $("#button_pago").css('display', 'block')
        $("#btn_pagoefectivo").css('display','none')

        function loadScriptKR(src, llave, callback) {

          $('#response_page').val('');
          $('#button_pago').empty();
          $('#payment_kr').remove();
          $('#css_izi').remove();
          $('#funciones_izi').remove();
          $('#krtoolbar').remove();
          var script  = document.createElement("script");
          script.setAttribute("src", src);
          script.setAttribute('kr-public-key', llave);
          script.setAttribute('id', 'payment_kr');



          script.addEventListener("load", callback);
          setTimeout(() => {
            document.head.appendChild(script)
          },2000);
        };

        this.sedeService.CrearFormularioIziPay(this.entidadService.reservacion.izipay)
        .subscribe((data: any) => {

          try {
            if (data.tokenstatus.mensaje == "Token invalid") {
              this.router.navigate(['/']);
              this.spinner.hide();
            }
          } catch (error) {

          }
          // console.log(data);


          setTimeout(() => {
            $('#button_pago').append('<div class="kr-embedded" kr-popin kr-form-token="' + data.answer.formToken + '" id="form_login">' +
              '<div id="alert" style="width:100%;display:none;color: #721c24 !important;background-color: #f8d7da !important;border-color: #f5c6cb !important;position: relative !important;padding: .75rem 1.25rem !important;margin-bottom: 1rem !important;border: 1px solid transparent !important;border-radius: .25rem !important;"></div> <div class="kr-pan"></div>' +
              '<div class="kr-expiry"></div>' +
              '<div class="kr-security-code"></div>' +
              '<button class="kr-payment-button"  ></button>' +
              ' <div class="customerror"></div>' +
              '</div>');
          }, 1500);


          var script_tag = document.createElement('script');
          var that = this;
          loadScriptKR('https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js',this.entidadService.reservacion.llave_publica, function(){

              document.head.appendChild(script_tag);
              setTimeout("var script_tag2 = document.createElement('script');" +
              "script_tag2.setAttribute('src', 'https://sistemas.centromedicoosi.com/izipay/funciones_izipay.js');" +

              "script_tag2.setAttribute('id', 'funciones_izi');" +
              "document.head.appendChild(script_tag2);", 2000);


              setTimeout(() => {
                $(".kr-popin-button").addClass("button")
                that.spinner.hide();
                    }, 1000);
                that.spinner.hide();
          });

        }, error => {
          console.log("Error");
        });
      }
      else{
        $("#button_pago").css('display', 'block')
        $("#btn_pagoefectivo").css('display','none')
        this.spinner.hide();
      }


    }else{
      if(this.entidadService.reservacion.izipayButton == 1){
        this.entidadService.reservacion.izipayButton = 0;
        this.entidadService.saveReservacion();
      }

      $("#button_pago").css('display', 'none')
      $("#btn_pagoefectivo").css('display','block')
      this.spinner.hide();
    }

  }

  onChange(deviceValue) {
    if(deviceValue == "No"){
      $("#selectTiposeguro").addClass("hidden")
      this.formNoSeguro = true;
      this.formSiSeguro = false;
      if (this.entidadService.usuario && this.entidadService.reservacion.hora) {
        const nombreCompleto = this.entidadService.usuario.name.split(' ');
        this.nombre = nombreCompleto[0];
  debugger
        this.detalle = {
          paciente: this.entidadService.usuario.apellidos + ', ' + this.entidadService.usuario.name,
          sede: this.entidadService.reservacion.nombresede,
          sexo: this.entidadService.reservacion.sexodoctor,
          idmedico: this.entidadService.reservacion.hora.idmedico,
          medico: this.entidadService.reservacion.hora.nombre,
          fecha: this.entidadService.reservacion.hora.inicioms,
          hora: this.entidadService.reservacion.hora.inicioms,
          precioDet: ((this.entidadService.reservacion.izipay.precio) / 100 ).toFixed(2)
        };
        console.log(this.detalle)

      } else {
        console.log('A');
        this.entidadService.reservacion.cita1 = 0;
      this.entidadService.saveReservacion();
        this.router.navigate(['/seleccione-sede']);
      }
    }
    else{
      $("#selectTiposeguro").removeClass("hidden")
      selectsForms();
      this.formSiSeguro = true;
      this.formNoSeguro = false;

    }
}
  indexTurnos(fecha: string): void {
    if (!fecha) {
      this.turnos = [];
      this.appointmentForm.reset();
      return;
    }

    this.loadTurnos = true;
    this.appointmentForm.get('fecha').setValue(fecha);
    this.appointmentForm.get('hora').setValue(null);
    const param = {
      fecha,
      idperfil: 3,
      idsede: this.entidadService.reservacion.idsede
    };

    this.spinner.show();
    this.citamedicaService.turnos(param)
      .subscribe((data: any) => {
        this.spinner.hide();
        const milisegundosactual = new Date().getTime();

        data.forEach((row) => {
          row.horas.forEach((hora) => {
            hora.inicioms = this.utils.formatoMs(fecha, hora.inicio);
            hora.finms = this.utils.formatoMs(fecha, hora.fin);
          });

          const horasValida = row.horas.filter((hora) => {
            return hora.inicioms > milisegundosactual;
          });

          row.horas = horasValida;
        });

        const dataHoras = data.filter((row) => {
          return row.horas.length > 0;
        });

        this.turnos = dataHoras;

        setTimeout(() => {
          this.checkTurnos();
        }, 100);

        this.loadTurnos = false;
      });
  }

  selectHora(turno: string): void {
    this.spinner.show();
    $('#idnext').css('display','none');




     this.appointmentForm.get('hora').setValue(turno);
  }





  createForm(): FormGroup {
    const idseguro = null;
    return new FormGroup({
      idseguro: new FormControl(null, Validators.required)
    });
  }
  createDetalleForm(): FormGroup {

    return new FormGroup({
      idseguro: new FormControl(null, Validators.required)
    });
  }

  save_pagoefectivo() : void{

    const datosPaciente = JSON.parse(sessionStorage.getItem('data')) ;
    const param = {
      idsede: parseInt(this.entidadService.reservacion.idsede, 10),
      namesede: this.entidadService.reservacion.nombresede,
      pe_idservice: this.entidadService.reservacion.izipay.pe_idservice,
      pe_accesskey: this.entidadService.reservacion.izipay.pe_accesskey,
      pe_secretkey: this.entidadService.reservacion.izipay.pe_secretkey,
      nombre: this.entidadService.usuario.name,
      apellidos: this.entidadService.usuario.apellidos,
      dni: datosPaciente.entidad.numerodoc,
      celular: datosPaciente.entidad.celular,
      idmedico: this.entidadService.reservacion.hora.idmedico,
      namemedico: this.entidadService.reservacion.hora.nombre,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      idestado: 4, // pendiente
      idestadopago: 72, // pendiente
      idreferencia: 16, // Pagina web
      idatencion: 70, // Portal paciente
      email: this.entidadService.usuario.email,
      precio: (this.entidadService.reservacion.izipay.precio / 100),
      orderid: this.entidadService.reservacion.orderid,
      bloqueo:1
    };
    this.spinner.show();
    this.citamedicaService.CreatePagarPe({ citamedica: param }).subscribe((data) => {
      this.spinner.hide();

      if (data.type === 'success') {
        console.log('array.data', data.data);
        this.entidadService.reservacion.pagado = '2';
        this.entidadService.reservacion.user_message = data.data.user_message;
        this.entidadService.reservacion.filenamepdf = data.data.filenamepdf;
        this.entidadService.reservacion.cip = data.data.cip;
        this.entidadService.reservacion.cipurl = data.data.urlCip;
        this.entidadService.saveReservacion();
        this.router.navigate(['/reservacion-realizada']);
      } else {
        this.detallecitas.error=data.data;
        $("#modal_cita_appoiment").addClass('is-visible')

        this.errors = data.data;
        setTimeout(() => {
          this.errors = null;
        }, 10000);
      }
    }, error => {
      $("#modal_cita_appoiment").addClass('is-visible')
      this.detallecitas.error="Hubo un problema con los servicios intentar nuevamente.";
      this.spinner.hide();
      this.router.navigate(['/']);

      setTimeout(() => {
        this.errors = null;
      }, 10000);
    });
  }

  checkTurnos(): void {
    const allInputs = $('.pick-turn-mod__dates input');
    $('.pick-turn-mod__dates').each(function () {
      $(this).find('button').each(function () {
        $(this).on('click', function (e) {
          e.preventDefault();
          allInputs.attr('checked', false);
          $(this).siblings().attr('checked', true);
        });
      });
    });
  }


  openPagar(): void {
    var val = $('#response_page').val();
    console.log("val", val)
    if (val != undefined) {
      var response_page = JSON.parse(val);

        this.entidadService.reservacion.pagado = '1';
        this.entidadService.reservacion.user_message = "";
        this.entidadService.reservacion.filenamepdf = "";
        this.entidadService.saveReservacion();
        this.next();

      this.save(response_page.clientAnswer.orderDetails.orderId, response_page.clientAnswer.transactions[0].transactionDetails.cardDetails.legacyTransId);
    }
  }


  onChangePlan(event){

    // $("#selectPlanseguro").removeClass("hidden")
    // selectsForms();
    var evento = event.split('-');
    this.codiafaplan=evento[1];
    this.codiafaidplan=evento[0];

  }
  consultar_cobertura(): void {
    this.entidadService.reservacion.seguroplan = this.selectedplan;
    // this.entidadService.reservacion.cipurl = data.data.urlCip;

    this.entidadService.saveReservacion();
    const datosPaciente = JSON.parse(sessionStorage.getItem('data')) ;

    this.spinner.show();

    this.citamedicaService.IpreesSited()
      .subscribe((data: any) => {

        this.iprees_sited = data.data.ipress;
        this.beneficio = data.data.beneficio;
        // console.log("DATOS",data);
        this.ositedsService.GetIafasOsiteds()
          .subscribe((datosIafas: any) => {
            // console.log("IAFAS",datosIafas);
            this.iafas = datosIafas.map(function (row) {
              return {
                numcodiafa: row.CO_IAFASCODE,
                nombre: row.NO_SHORTNAME,
                logo: null,
                descripcion: row.NO_IAFASNAME
              }
            });
            this.detalleSeguro = {
              idpaciente: this.entidadService.usuario.id,
              aIafas: this.iafas,
              numCodIafa: this.codiafaidplan,
              IpressList: this.iprees_sited,
              BeneficioList: this.beneficio,
              idsede: this.entidadService.reservacion.idsede,
              idaseguradoraplan: this.codiafaidplan
            };
            console.log(this.detalleSeguro);

            this.sedesdeipress = [];
            this.sedesdeipressTemp = [];
            // vm.cambiarSedeDeIpress = function (producto) {
              var that0 = this;
            this.ositedsService.GetSedesDeIpress()
              .subscribe((resOsiteds: any) => {
                if (resOsiteds.success==false) {
                  // Notification.warning({ message: 'No hay respuesta del SitedWeb se habilita los campos para llenar' });
                  // $uibModalInstance.close(false);
                  return false;
                }else{
                  that0.sedesdeipress = resOsiteds;
                  that0.sedesdeipressTemp =resOsiteds;
                  console.log("SEDEIPRESS",that0.sedesdeipress)
                  that0.validadEntidadVinculada();
                }

              });

            });
    });

  }

  closeModal(){
    $("#modal").removeClass('is-visible')
    $("#overlay").removeClass('is-visible')
  }
  closeModalCita(){
    $("#modal").removeClass('is-visible')
    $("#overlay").removeClass('is-visible')
    this.entidadService.reservacion.cita1 = 0;
      this.entidadService.saveReservacion();
    this.router.navigate(['/seleccione-sede']);
  }

  validadEntidadVinculadaChange = function () {

    // vm.spinerLoadingHorasSited = false;

    // this.numCodIafaCG = vm.numCodIafa;
    this.sededeipressCG = this.sededeipress;

    this.filaSelectBusqueda = [];
    this.gridBusqueda.data = [];
    this.gridBeneficios.data = [];
    this.sitedsDetalle = null;
    this.restricciones = null;
    console.log("coco",this.codiafaplan)
    console.log("sede",this.sededeipress)
    this.ositedsService.GetEntidadVinculada({
      numCodIafa: this.codiafaplan,
      numCodIpress: this.sededeipress,
      numRuc: '20431738806'
    }).subscribe((resOsiteds: any) => {

      if (resOsiteds.coError) {
        this.entVinculada = false;
        console.log(" resOsiteds.desError",  resOsiteds.desError)
      //  Notification.error({ message: resOsiteds.desError });
      } else {
        if (resOsiteds.nomCodPoseeEntVinculada === 'Y') {
          this.entVinculada = true;
          this.spinerLoadingHorasSited = true;
          var mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
          // Notification.primary({ message: mensaje });
          this.loadBusqueda();
        }

        if (resOsiteds.nomCodPoseeEntVinculada === 'N' || resOsiteds.nomCodPoseeEntVinculada  == '0') {
          this.entVinculada = false;
          this.spinerLoadingHorasSited = true;
          var mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'No posee entidad vinculada';
          // Notification.error({ message: mensaje });
        }
      }
    });
  }

  validadEntidadVinculada(){
    var mensaje = "";
    var num_carrera = 1;
    // vm.numCodIafaCG = vm.numCodIafa;
    var status = false;
    this.filaSelectBusqueda = [];
    this.gridBusqueda = [];
    this.gridBeneficios = [];
    this.sitedsDetalle = null;
    this.restricciones = null;
    this.idsedeRes = this.entidadService.reservacion.idsede;
    var thatt = this;
    console.log("carrera",num_carrera)
    this.sededeipress = this.iprees_sited.filter(function(row) {
      return row.idsede == thatt.idsedeRes && row.n_carrera == num_carrera;
    }); //primera carrerra
    if(this.sededeipress.length > 0){
      this.sededeipress = this.sededeipress[0].codigo_sited
    }
    var that = this;
    console.log("codiafaplan",this.codiafaplan)
    console.log("codiafaidplan",this.codiafaidplan)
    this.ositedsService.GetEntidadVinculada({
        numCodIafa: this.codiafaplan,
        numRuc: '20431738806',
        numCodIpress: this.sededeipress,
    }).subscribe((resOsiteds: any) => {
      console.log(resOsiteds)
      this.spinner.show("carrera2");
      this.spinner.hide();

      if (resOsiteds.nomCodPoseeEntVinculada === 'Y') {
        this.entVinculada = true;
        status = true;
        mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
        console.log("Mensaje 1",mensaje);
        this.loadBusqueda();
      }
      if(resOsiteds.desMensajeEntidadVinculada == "Mensaje Entidad Vinculada"){
        this.entVinculada = true;
        status = true;
        mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
        console.log("Mensaje",mensaje);
        this.loadBusqueda();
      }
      if ( resOsiteds.coError==0 || resOsiteds.nomCodPoseeEntVinculada === 'N' || resOsiteds.nomCodPoseeEntVinculada  == '0') {
        console.log("carrera1",num_carrera);
        num_carrera++; //segunda carrerra
        console.log("carrera 2",num_carrera)
        that.entVinculada = false;
        mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'No posee entidad vinculada';
        that.sededeipress = that.iprees_sited.filter(function(row) {
            return row.idsede == that.idsedeRes && row.n_carrera == num_carrera;
        });
        if(that.sededeipress.length > 0){
          that.sededeipress = that.sededeipress[0].codigo_sited
        }
        var that2 = that;
        that.ositedsService.GetEntidadVinculada({
          numCodIafa: that.codiafaplan,
          numCodIpress: that.sededeipress,
          numRuc: '20431738806'
        }).subscribe((resOsiteds: any) => {
          this.spinner.show("carrera3");
          this.spinner.hide("carrera2");
          if (resOsiteds.nomCodPoseeEntVinculada === 'Y') {
            that2.entVinculada = true;
            status = true;
            mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
            that2.loadBusqueda();
            console.log(mensaje);
          }
          if (resOsiteds.coError==0 || resOsiteds.nomCodPoseeEntVinculada === 'N' || resOsiteds.nomCodPoseeEntVinculada  == '0') {
            console.log("carrera3",num_carrera);
            num_carrera++; //tercera carrerra
            console.log("carrera 3",num_carrera)
            that2.entVinculada = false;
            mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'No posee entidad vinculada';
            that2.sededeipress = that2.iprees_sited.filter(function(row) {
                return row.idsede == that2.idsedeRes && row.n_carrera == num_carrera;
            });
            if(that2.sededeipress.length > 0){
              that2.sededeipress = that2.sededeipress[0].codigo_sited
            }
            var that3 = that2;
            that2.ositedsService.GetEntidadVinculada({
              numCodIafa: that2.codiafaplan,
              numCodIpress: that2.sededeipress,
              numRuc: '20431738806'
            }).subscribe((resOsiteds: any) => {

              this.spinner.show("carrera4");
              this.spinner.hide("carrera3");
              if (resOsiteds.nomCodPoseeEntVinculada === 'Y') {
                that3.entVinculada = true;
                status = true;
                mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
                that3.loadBusqueda();
                console.log(mensaje);
              }

              if ( resOsiteds.coError==0 ||resOsiteds.nomCodPoseeEntVinculada === 'N' || resOsiteds.nomCodPoseeEntVinculada  == '0') {
                num_carrera++; //cuarta carrerra
                that3.entVinculada = false;
                mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'No posee entidad vinculada';
                that3.sededeipress = that3.iprees_sited.filter(function(row) {
                    return row.idsede == that3.idsedeRes && row.n_carrera == num_carrera;
                });
                if(that3.sededeipress.length > 0){
                  that3.sededeipress = that3.sededeipress[0].codigo_sited
                }
                var that4 = that3;
                that3.ositedsService.GetEntidadVinculada({
                  numCodIafa: that3.codiafaplan,
                  numCodIpress: that3.sededeipress,
                  numRuc: '20431738806'
                }).subscribe((resOsiteds: any) => {
                    if (resOsiteds.nomCodPoseeEntVinculada === 'Y') {
                      that4.entVinculada = true;
                      status = true;
                      mensaje = resOsiteds.desMensajeEntidadVinculada ? resOsiteds.desMensajeEntidadVinculada : 'Si posee entidad vinculada';
                      that4.loadBusqueda();
                      console.log(mensaje);
                    }
                    if (resOsiteds.coError==0 || resOsiteds.nomCodPoseeEntVinculada === 'N' || resOsiteds.nomCodPoseeEntVinculada  == '0') {
                      that4.entVinculada = false;
                      this.spinner.hide("carrera4");
                      $("#modal").addClass('is-visible')
                      $("#overlay").addClass('is-visible')

                    }
                  });
              }
            });
          }
        });
      }else if(resOsiteds.coError){
        this.spinner.hide();
        this.spinner.hide("carrera2");
        this.spinner.hide("carrera3");
        this.spinner.hide("carrera4");
        $("#modal").addClass('is-visible')
        $("#overlay").addClass('is-visible')

        return false;
      }
    });
  }

  loadBusqueda(){
    this.filtroTipoBusqueda === 'iafa';
    this.loadBusquedaIafas();
  }

  loadBusquedaIafas(){
    if(!this.codiafaidplan){
      alert("Seleccione IAFA")
      return false;
    }
    if(!this.sededeipress){
      alert("Seleccione Ipress")
      return false;
    }

    this.filaSelectBusqueda = [];
    this.gridBusqueda.data = [];
    this.gridBeneficios.data = [];
    this.sitedsDetalle = null;
    this.restricciones = null;
    this.datosPacienteRegafi = null;
    const datosPaciente = JSON.parse(sessionStorage.getItem('data')) ;
    this.ositedsService.GetCodigoAfiliadoSiteds({
      numRuc: '20431738806',
      apePaternoOrNumDniAsegurado: datosPaciente.entidad.numerodoc,
      numCodIafa: this.codiafaplan,
      numCodIpress: this.sededeipress,
    }).subscribe((resOsiteds: any) => {


      if (resOsiteds.coError) {
        this.spinner.hide();
        this.spinner.hide("carrera2");
        this.spinner.hide("carrera3");
        this.spinner.hide("carrera4");
        $("#modal").addClass('is-visible')
        $("#overlay").addClass('is-visible')

        return false;
      } else {
        var reference = this;
        this.gridBusqueda.data = resOsiteds;
        if(this.gridBusqueda.data.length==1 && this.gridBusqueda.data[0].nomCodEstado=="VIGENTE" && this.gridBusqueda.data[0].nomProducto != "SCTR"){// cuando solo tiene un producto
          var sede = this.sedesdeipress.filter(function (row) {
            return row.CO_RENIPRESS == reference.sededeipress;
          })[0];
          console.log("row.CO_RENIPRESS 1", sede);
          this.capturarProducto(this.gridBusqueda.data[0]);
        }else if (this.nomProducto_temp){
          var sede = reference.sedesdeipress.filter(function (row) {
            return row.CO_RENIPRESS == reference.sededeipress;
          })[0];
        // Notification.primary({ message: 'Se esta buscado beneficio para la sede de '+sede.DISTRITO });
        var data_producto = reference.gridBusqueda.data.filter(function(row) {
          return row.nomProducto == reference.nomProducto_temp })[0]; //primera carrerra

          reference.capturarProducto(data_producto);
        }

        else if(this.gridBusqueda.data.length>1){
          var checkBeneficiario = false;
          this.gridBusqueda.data.forEach(function (row) {
            if(row.nomCodEstado == "VIGENTE" && row.numDocumentoAsegurado == datosPaciente.entidad.numerodoc && row.nomProducto != "SCTR"){
              checkBeneficiario = true;
              console.log("row.checkBeneficiario", checkBeneficiario);
              reference.capturarProducto(row);
            }
          });
          if(!checkBeneficiario){
            this.spinner.hide();
            this.spinner.hide("carrera2");
            this.spinner.hide("carrera3");
            this.spinner.hide("carrera4");
            $("#modal").addClass('is-visible')
            $("#overlay").addClass('is-visible')
          }
        }
        else{
          this.spinner.hide();
          this.spinner.hide("carrera2");
          this.spinner.hide("carrera3");
          this.spinner.hide("carrera4");
          $("#modal").addClass('is-visible')
          $("#overlay").addClass('is-visible')
        }
      }
      if (resOsiteds.success==false) {
        this.spinner.hide();
        this.spinner.hide("carrera2");
        this.spinner.hide("carrera3");
        this.spinner.hide("carrera4");
        $("#modal").addClass('is-visible')
        $("#overlay").addClass('is-visible')
        return false;
       }
    });

  }

  capturarProducto = function (producto) {
    debugger
    producto.numRuc = '20431738806';
    this.productodetalle=producto;
    if (producto.nomColorEstado === 'ROJO') {
      this.spinner.hide();
      this.spinner.hide("carrera2");
      this.spinner.hide("carrera3");
      this.spinner.hide("carrera4");
      $("#modal").addClass('is-visible')
      $("#overlay").addClass('is-visible')
      return false;
    }

    this.ProductoSeleccionado = producto
    this.filaSelectBeneficio = [];
    this.gridBeneficios.data = [];


    this.ositedsService.GetDetalleAseguradoSiteds(producto)
    .subscribe((resDetAsegOsiteds: any) => {
      // console.log("Captura prod",resDetAsegOsiteds);
        if (resDetAsegOsiteds.coError) {
          this.spinner.hide();
          this.spinner.hide("carrera2");
          this.spinner.hide("carrera3");
          this.spinner.hide("carrera4");
          $("#modal").addClass('is-visible')
          $("#overlay").addClass('is-visible')
          this.active = 0;
        } else {
          if (resDetAsegOsiteds.indAfiliadoSinCobertActConIpress === false) {
            this.active = 0;
            var thatdet = this;
            Object.keys(thatdet.sedesdeipressTemp).forEach(function(key){
              if (thatdet.sedesdeipressTemp[key]['CO_RENIPRESS'] === thatdet.sededeipress) {
                thatdet.sedesdeipressTemp[key]['ESTADO']=0;
              }
            });
            thatdet.num_carrera_global++;
            thatdet.nomProducto_temp = producto.nomProducto;
            thatdet.sededeipress = thatdet.iprees_sited.filter(function(row) {
              return row.idsede == thatdet.idsedeRes && row.n_carrera == thatdet.num_carrera_global;})[0].codigo_sited;
            thatdet.validadEntidadVinculadaChange();
            // this.spinner.hide();
            // this.spinner.hide("carrera2");
            // this.spinner.hide("carrera3");
            // this.spinner.hide("carrera4");
            // $("#modal").addClass('is-visible')
            // $("#overlay").addClass('is-visible')
          } else {
            // this.active = 1;
            this.sitedsDetalle = resDetAsegOsiteds
            this.gridBeneficios.data = resDetAsegOsiteds.beneficios;
            this.numDocPacRegafiBusqueda = this.sitedsDetalle.datosPaciente.numDocumento;
            // var gridBeneficios_data = resDetAsegOsiteds.beneficios;
            this.informacionGeneral = resDetAsegOsiteds.informacionGeneral;
            // var beneficioList =  this.beneficio;

            var that = this;
            var checkbeneficio = false;
            // console.log("Beneficios", this.gridBeneficios.data)
            this.gridBeneficios.data.forEach(function (row,index ) {
              that.beneficio.forEach(function (row2) {

                var array_plan=(row2.plan_seguros).split(';');

                var that2 = that;

                array_plan.forEach(function (row3) {

                  // alert(row3)
                  console.log("that.codiafaidplan",that.codiafaidplan)
                  if(row3==that.codiafaidplan){



                    if(row.numCodigoBeneficio==row2.codigo ){
                      that2.active = 1;
                      var detalleFinal = {
                        status_recomen_beneficio: true,
                        cobertura: row,
                        codbeneficio: row.numCodigoBeneficio,
                        deducible: row.desCopagoVariable,
                        coa: parseInt(row.numCopagoVariable)+'%',
                        recomen_beneficio: row,

                      };
                      // var sede = that2.sedesdeipress.filter(function (row) {
                      //   return row.CO_RENIPRESS == this.sededeipress;
                      // })[0];

                      // // that.guardaCobertura(detalleFinal);
                      // console.log("SEDE",sede);
                      checkbeneficio = true;
                      // alert("check "+checkbeneficio)
                      that2.guardaCobertura(detalleFinal);
                      return;
                    }

                  }

                });

              });
            });

            if(!checkbeneficio){
                  // if(this.cobertura== undefined){
                    // console.log("that.sededeipress",that.sededeipress)
                    // console.log("that2.sedesdeipress",that2.sedesdeipress);
                    var sede = that.sedesdeipress.filter(function (row) {
                      return row.CO_RENIPRESS == that.sededeipress;
                    })[0];
                    // console.log("sedeaa",sede)
                    // Notification.warning({ message: 'No se encontro beneficio para la sede de '+sede.DISTRITO });
                    // console.log("row.CO_RENIPRESS",row.CO_RENIPRESS);
                    // console.log("that.sedesdeipressTemp",that.sedesdeipressTemp);
                    Object.keys(that.sedesdeipressTemp).forEach(function(key){
                      // console.log("aaa",that.sededeipress)
                      if (that.sedesdeipressTemp[key]['CO_RENIPRESS'] === that.sededeipress) {
                        that.sedesdeipressTemp[key]['ESTADO']=0;
                      }
                    });
                    that.num_carrera_global++;
                    // console.log("that.iprees_sited",that.iprees_sited)
                    // console.log("that.num_carrera_global",that.num_carrera_global)
                    that.sededeipress = that.iprees_sited.filter(function(row) {
                      // console.log("ROW",row)
                      // console.log("that.idsede555",that.idsedeRes)
                      // console.log("that.num_carrera_global",that.num_carrera_global)
                      return row.idsede == that.idsedeRes && row.n_carrera == that.num_carrera_global;
                    });
                    if(that.sededeipress.length > 0){
                      that.sededeipress = that.sededeipress[0].codigo_sited
                    }


                    // console.log("that",that.sededeipress);

                    that.loadBusquedaIafas();
                  // }

                  // that.spinner.hide();
                  // that.spinner.hide("carrera2");
                  // that.spinner.hide("carrera3");
                  // that.spinner.hide("carrera4");
                  // $("#modal").addClass('is-visible')
                  // $("#overlay").addClass('is-visible')
            }
          }
        }
      });

    var desCondicionesMedicos = null;

    this.ositedsService.GetCondicionesMedicas(producto)
      .subscribe((resDetAsegOsiteds: any) => {
        this.oCondicionesMedicas = resDetAsegOsiteds;
        this.indExisteCondicion = resDetAsegOsiteds.indExisteCondicion;
      });
  }

  guardaCobertura(detalle): void {
    // console.log(detalle);
    this.entidadService.reservacion.coa = detalle.coa;
    this.entidadService.reservacion.cobertura = detalle.cobertura;
    this.entidadService.reservacion.deducible = detalle.deducible;
    this.entidadService.reservacion.codbeneficio = detalle.codbeneficio;
    this.entidadService.reservacion.poliza = this.informacionGeneral.numPoliza;
    this.entidadService.reservacion.codasegurado = this.informacionGeneral.numCodAsegurado;
    this.entidadService.reservacion.sedeipress = this.sededeipress;
    this.entidadService.saveReservacion();

    this.spinner.hide();
    this.router.navigate(['/detalles-seguro']);

  }

  next(): void {
    this.router.navigate(['/reservar-y-pagar']);
  }

  save(orderid: string, transaction: string): void {
    const param = {
      idsede: parseInt(this.entidadService.reservacion.idsede, 10),
      idmedico: this.entidadService.reservacion.hora.idmedico,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      idestado: 4, // pendiente
      idreferencia: 16, // Pagina web
      idatencion: 70, // Portal paciente
      email: this.entidadService.usuario.email,
      orderid: orderid,
      transaction: transaction
    };

    this.spinner.show();
    this.citamedicaService.CreatePagar({ citamedica: param }).subscribe((data) => {
      this.spinner.hide();

      if (data.type === 'success') {
        console.log('array.data', data.data);
        this.entidadService.reservacion.pagado = '1';
        this.entidadService.reservacion.user_message = data.data.user_message;
        this.entidadService.reservacion.filenamepdf = data.data.filenamepdf;
        this.entidadService.saveReservacion();
        this.router.navigate(['/reservacion-realizada']);
      } else {
        this.errors = data.data;
        setTimeout(() => {
          this.errors = null;
        }, 10000);
      }
    }, error => {
      this.spinner.hide();
      setTimeout(() => {
        this.errors = null;
      }, 10000);
    });

  }

}
