import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./utils";
export class UbigeoService extends ServiceBase {
    constructor(http, utils) {
        super();
        this.http = http;
        this.utils = utils;
    }
    GetIndex(request) {
        return this.getQuery('osi/ubigeo', request).pipe(map(data => {
            return data;
        }));
    }
}
UbigeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UbigeoService_Factory() { return new UbigeoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Utils)); }, token: UbigeoService, providedIn: "root" });
