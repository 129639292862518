// const local         = 'http://lumenionic.pe';
const local         = 'https://api.centromedicoosi.com';
// const local         = 'http://localhost/api-osi-erp/public/';
const produccion    = 'https://api.centromedicoosi.com';

const localSiteds         = 'https://ositeds.centromedicoosi.com';
const produccionSiteds    = 'https://ositeds.centromedicoosi.com';

export const URL_SERVICIOS = location.hostname === 'localhost' ? local : produccion;

export const URL_SERVICIOS_SITEDS = location.hostname === 'localhost' ? localSiteds : produccionSiteds;
