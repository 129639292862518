import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SedeService extends ServiceBase {
    constructor(http) {
        super();
        this.http = http;
    }
    // Metodos 
    CrearFormularioIziPay(objeto) {
        return this.postQuery('osi/citamedica/CrearFormularioIziPay', objeto).pipe(map(data => {
            return data;
        }));
    }
    index(params) {
        return this.getQuery('osi/sederc', params).pipe(map(data => {
            return data.data;
        }));
    }
    show(id, params) {
        return this.getQuery(`osi/sede/${id}`, params);
    }
}
SedeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SedeService_Factory() { return new SedeService(i0.ɵɵinject(i1.HttpClient)); }, token: SedeService, providedIn: "root" });
