<app-header></app-header>

<!-- main content -->
<div class="content">
  <div class="steps-wrapper">
    <div class="message">
      <div class="message__step message__step--active step-message-1">
        <p class="font-light">4 de 4</p>
        <h2 class="text-2xl font-medium mt-5 mb-10">Reserve una cita<br />médica particular</h2>
      </div>

    </div>
    <div class="progress">
      <div class="progress__step step-1-ico progress__step--done"><span class="progess__step-text">Datos
          personales</span></div>
      <div class="progress__step step-2-ico progress__step--done"><span class="progess__step-text">Horarios y
          Doctores</span></div>
      <!-- <div class="progress__step step-3-ico progress__step--done"><span class="progess__step-text">Cuenta OSI</span></div> -->
      <div class="progress__step step-3-ico progress__step--done"><span class="progess__step-text">Cobertura,
          condiciones y pago</span></div>
      <div class="progress__step progress__step--done step-4-ico"><span class="progess__step-text">Resumen</span></div>
    </div>
  </div>


  <div class="form-wrapper">
    <h3 class="form-reservation__title">Tiene una reserva con nosotros</h3>
    <div class="resume__wrapper">
      <div class="resume__schedule md:flex">
        <div class="resume__section md:flex-1">
          <p>Paciente:</p>
          <p class="font-bold">{{detalle.paciente}}</p>
        </div>
        <div class="resume__section md:flex-1">
          <p>Sede:</p>
          <p class="font-bold">{{detalle.sede}}</p>
        </div>
      </div>

    </div>

    <div class="resume__wrapper">
      <div class="resume__schedule md:flex">
        <div class="resume__section md:flex-1">
          <p>Fecha de la cita médica:</p>
          <p class="font-bold"> {{detalle.fecha | date:'EEEE, d \'de\' MMMM y' | titlecase}}</p>
        </div>
        <div class="resume__section md:flex-1">
          <p>Hora de la cita médica:</p>
          <p class="font-bold">{{detalle.hora | date:'h:mm a' }}</p>
        </div>
      </div>
    </div>

    <div class="resume__wrapper">
      <div class="resume__section">
        <p>Especialista que atenderá su consulta:</p>
      </div>
    </div>
    <div class="card">
      <div class="doctor">
        <img class="doctor__image" src="./assets/images/doctores/{{detalle.idmedico}}.jpg"
          onError="this.src='./assets/images/doctores/icon_doctor.jpg'" alt="Doctor 2">
        <div class="doctor__content">
          <p class="doctor__fullname">
            <span class="doctor__name">{{detalle.sexo == 'F' ? 'Dra.' :' Dr. '}} {{detalle.medico}}</span>
          </p>
          <p class="doctor__cmp">Especialidad: <span> Medicina Física y Rehabilitación</span></p>

        </div>
      </div>
    </div>
    <div class="resume__wrapper" *ngIf="!pagadoCero">
      <div class="resume__consult-type resume__section">
        <p>Monto a pagar:</p>
        <p class="font-bold"> S/. {{detalle.precio}}</p>
      </div>

    </div>

    <hr>
    <div class="resume__wrapper" *ngIf="!pagado">
      <div class="resume__schedule md:flex">
        <div class="resume__section md:flex-1">
          <p>Codigo de pago CIP:</p>
          <p class="font-bold"> {{detalle.codigoCIP}}</p>
        </div>
        <div class="resume__section md:flex-1">
          <p>Como pagar:</p>
          <p class="font-bold"> <a href="https://pagoefectivo.pe/como-pagar.html"
              target="_blank">https://pagoefectivo.pe/como-pagar.html</a></p>
        </div>
      </div>

    </div>


    <div class="resume__wrapper" *ngIf="!pagado">
      <div class="resume__consult-type resume__section">
        <p class="color-sec-2" *ngIf="!pagado">
          Se ha enviado los detalles de la reserva y del pago a tu número celular.
        </p>
        <p class="color-sec-2">
          Si deseas ver más detalles de tu cita puedes ir a tu
          intranet de paciente.
        </p>
      </div>

    </div>

    <div class="resume__wrapper" *ngIf="!pagado">
      <div class="resume__consult-type resume__section">
        <p class="color-sec-2" *ngIf="!pagado">
          Tenga en cuenta lo siguiente:
        </p>
        <p class="color-sec-2">
          <li *ngIf="!pagado">Toda cita medica se confirma previo pago.</li>
          <li *ngIf="!pagado">La pre-reserva tiene vigencia de 4 horas. Sino realiza el pago, luego de ese tiempo, su
            reserva se eliminará.</li>
          <li *ngIf="!pagado">Realizar el pago confirma la lectura y aceptación de los términos y condiciones de
            atención. </li>
          <li>Puede leer los términos y condiciones de atención haciendo <a
              href="https://centromedicoosi.com/condiciones-de-atencion1.pdf" target="_blank">click aquí</a></li>
        </p>
      </div>

    </div>

    <div class="alert alert-danger animate__animated animate__headShake" role="alert" *ngIf="errors"
      style="margin-top: 5px">
      {{errors}}
    </div>
    <br>
    <!-- card footer -->
    <div class="resume__wrapper" style="text-align: center;">
      <div
        class="col d-flex justify-content-between justify-content-md-center align-items-center flex-column flex-sm-row-reverse flex-md-column flex-lg-row-reverse">
        <button type="button" class="button_final mb-3 mb-sm-0 mb-md-3 mb-lg-0"
          (click)="openUrl('https://pacientes.centromedicoosi.com/')">
          <span>Ir a mi Intranet</span>
        </button>
        <button type="button" class="button_final button_final--ghost  mr-md-5"
          (click)="openUrl('https://www.centromedicoosi.com/')">
          <span>Visitar la web</span>
        </button>
      </div>
    </div>
    <br>
    <div class="resume__wrapper" style="text-align: center;">
      <a [routerLink]="'/'" class="text-body font-size-small">
        <u>Volver</u>
      </a>
    </div>
    <br>
  </div>

</div>
