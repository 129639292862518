/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./header.component";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../services/citamedica.service";
var styles_HeaderComponent = [".alert-warning[_ngcontent-%COMP%] {\n    color: #664d03;\n    background: #fff3cd;\n    border-color: #ffecb5;\n  }"];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-clock-o float-right"], ["style", "font-size: 35px;margin-top: -35px;margin-right: 21px;"]], null, null, null, null, null))], null, null); }
function View_HeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "float-right"], ["style", "margin-right: 67px;margin-top: -39px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["style", "color:red;font-size: 30px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.counter)); _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert-warning"], ["style", "text-align: center;padding: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Alerta!"])), (_l()(), i0.ɵted(-1, null, [" Se acabo el tiempo de session para registro de citamedica. "]))], null, null); }
export function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.FormatTimePipe, [i2.Router]), (_l()(), i0.ɵeld(1, 0, null, null, 9, "header", [["class", "header content-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "overlay_sede"], ["id", "overlay"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "OSI"], ["class", "logo inline lg:hidden"], ["height", "33"], ["src", "./assets/images/logo-mobile.png"], ["width", "164"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", "OSI"], ["class", "logo inline hidden lg:block"], ["height", "47"], ["src", "./assets/images/logo.png"], ["width", "225"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.time_status; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.time_status; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.estado_session_pago; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i1.HeaderComponent, [i4.CitamedicaService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i0.ɵccf("app-header", i1.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
