/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nopagefound.component";
import * as i2 from "../../services/entidad.service";
import * as i3 from "@angular/router";
var styles_NopagefoundComponent = [];
var RenderType_NopagefoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NopagefoundComponent, data: {} });
export { RenderType_NopagefoundComponent as RenderType_NopagefoundComponent };
export function View_NopagefoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["nopagefound works!"]))], null, null); }
export function View_NopagefoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-nopagefound", [], null, null, null, View_NopagefoundComponent_0, RenderType_NopagefoundComponent)), i0.ɵdid(1, 114688, null, 0, i1.NopagefoundComponent, [i2.EntidadService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NopagefoundComponentNgFactory = i0.ɵccf("app-nopagefound", i1.NopagefoundComponent, View_NopagefoundComponent_Host_0, {}, {}, []);
export { NopagefoundComponentNgFactory as NopagefoundComponentNgFactory };
