import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { EntidadService } from "src/app/services/entidad.service";
import { CitamedicaService } from "src/app/services/citamedica.service";
import { Router, NavigationExtras } from "@angular/router";
import { SedeService } from "src/app/services/sede.service";
import { FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { Utils } from "src/app/services/utils";
import { NgxSpinnerService } from "ngx-spinner";
import { HeaderComponent } from "../../shared/header/header.component";

declare var $: any;
declare function init_location();
declare function selectsForms();

@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styles: [],
})
export class LocationComponent implements OnInit {
  @ViewChild("selectSede", { static: false }) selectSede: ElementRef;

  idsedevalue = 1;
  MedioPago = false;
  citalibre = true;
  detallecitas: any = [];
  sedeForm: FormGroup;
  horarioForm: FormGroup;
  nombre: string;
  apellidopat: string;
  celular: string;
  sedes: any[] = [];
  sedesList: any[] = [];
  loadSede = false;
  errors: string = null;
  errores: string = null;
  test: string;
  turnos: any[] = [];
  loadTurnos = false;
  idsede = null;
  tipoconsulta: string = "";
  turnoinicio = null;
  turnofin = null;
  medico = null;
  fechacita: string = null;
  nodisponible: boolean;
  nodisponibleDay: boolean;
  checksede: any = false;
  ValidarCitas: boolean;
  sexodoctor: any;
  constructor(
    private entidadService: EntidadService,
    private sedeService: SedeService,
    private citamedicaService: CitamedicaService,
    private router: Router,
    private ngZone: NgZone,
    private utils: Utils,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    init_location();

    if (sessionStorage) {
      if (this.entidadService.usuario) {
        const nombreCompleto = this.entidadService.usuario.name.split(" ");
        this.nombre = nombreCompleto[0];
      } else {
        const nombreCompleto = sessionStorage.getItem("data").split(" ");
        this.nombre = nombreCompleto[0];
      }
    } else {
      this.router.navigate([""]);
    }
  }

  // Data inicial y listados
  tipocitas: any[] = [
    { idtipocita: "", abreviatura: "Seleccione" },
    { idtipocita: 2, abreviatura: "Presencial" },
    // { idtipocita: 1, abreviatura: 'Online' }
  ];

  eliminarSession() {
    const datosPaciente = JSON.parse(sessionStorage.getItem("data"));
    if (datosPaciente) {
      this.citamedicaService
        .destroyCitasTemporales({ id: datosPaciente.entidad.identidad })
        .subscribe((data: any) => {
          sessionStorage.clear();
          localStorage.clear();
          HeaderComponent.time_status_ = false;
        });
    }
  }

  ngOnInit() {
    this.entidadService.reservacion.cita1 = 0;
    this.entidadService.saveReservacion();
    this.spinner.show();
    window["angularComponentReference"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (data: any) => this.indexTurnos(data),
    };

    this.sedeForm = this.createForm();
    this.horarioForm = this.createFormHorario();
    this.indexSedes();
    setTimeout(() => {
      selectsForms();
      init_location();
    }, 1000);
  }

  indexTurnos(fecha: string): void {
    if (!fecha) {
      this.turnos = [];
      this.horarioForm.reset();
      return;
    }

    this.fechacita = fecha;
    this.loadTurnos = true;
    this.horarioForm.get("fecha").setValue(fecha);
    this.horarioForm.get("hora").setValue(null);
    const param = {
      fecha,
      idperfil: 3,
      idsede: this.idsede,
    };

    this.spinner.show();
    this.citamedicaService.turnos(param).subscribe((data: any) => {
      this.ValidarCitas = false;
      this.spinner.hide();

      const milisegundosactual = new Date().getTime();

      data.forEach((row) => {
        row.horas.forEach((hora) => {
          hora.inicioms = this.utils.formatoMs(fecha, hora.inicio);
          hora.finms = this.utils.formatoMs(fecha, hora.fin);
        });

        const horasValida = row.horas.filter((hora) => {
          return hora.inicioms > milisegundosactual;
        });

        row.horas = horasValida;
      });

      const dataHoras = data.filter((row) => {
        return row.horas.length > 0;
      });

      this.turnos = dataHoras.reverse();
      if (this.turnos.length > 0) {
        this.nodisponible = false;
      } else {
        this.nodisponible = true;
      }
      this.loadTurnos = false;
    });
  }

  indexTurnosDay(fecha: string): void {
    if (!fecha) {
      this.turnos = [];
      this.horarioForm.reset();
      return;
    }

    this.fechacita = fecha;
    this.loadTurnos = true;
    this.horarioForm.get("fecha").setValue(fecha);
    this.horarioForm.get("hora").setValue(null);
    const param = {
      fecha,
      idperfil: 3,
      idsede: this.sedeForm.get("idsede").value,
    };
    this.nodisponibleDay = false;

    this.spinner.show();
    this.citamedicaService.turnos(param).subscribe((data: any) => {
      this.spinner.hide();

      const milisegundosactual = new Date().getTime();

      data.forEach((row) => {
        row.horas.forEach((hora) => {
          hora.inicioms = this.utils.formatoMs(fecha, hora.inicio);
          hora.finms = this.utils.formatoMs(fecha, hora.fin);
        });

        const horasValida = row.horas.filter((hora) => {
          return hora.inicioms > milisegundosactual;
        });

        row.horas = horasValida;
      });

      const dataHoras = data.filter((row) => {
        return row.horas.length > 0;
      });
      console.log(" this.turnos", this.turnos.length);
      if (this.turnos.length > 0) {
        this.nodisponibleDay = false;
      } else {
        this.nodisponibleDay = true;
      }
      // this.loadTurnos = false;
    });
  }

  indexSedes(): void {
    this.loadSede = true;
    this.sedeService.index({ preciocm: "1" }).subscribe(
      (data: any) => {
        this.spinner.show();
        this.sedes = data.filter((row) => {
          return row.comercial === "1";
        });
        this.sedesList = data.filter((row) => {
          return row.comercial === "1" && row.idsede != 15;
        });
        this.onChange("Presencial");

        this.loadSede = false;
        this.spinner.hide();
      },
      (error) => {
        console.log("Error");
      }
    );
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  closeModalProx() {
    $("#modal_prox").removeClass("is-visible");
    $("#overlay").removeClass("is-visible");
    // window.location.reload();
    this.reloadCurrentRoute();
  }
  onChangeSede(sedes) {
    $("#datepicker").val("");
    this.turnos.length = 0;
    if (this.sedeForm.get("idsede").value == 200) {
      $("#modal_prox").addClass("is-visible");
      $("#overlay").addClass("is-visible");
      selectsForms();
    } else {
      this.spinner.show();
      $("#grupo-fecha").removeClass("hidden");

      $("#datepicker").datepicker("destroy");
      const idsede = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].idsede;

      const nombresede = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].nombre;

      const preciocm = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].preciocm;

      const usu_izipay = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].usu_izipay;

      const passw_izipay = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].passw_izipay;

      const clave_publica_izipay = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].clave_publica_izipay;

      const pe_idservice = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].pe_idservice;

      const pe_accesskey = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].pe_accesskey;

      const pe_secretkey = this.sedes.filter((row) => {
        return row.idsede === this.sedeForm.get("idsede").value;
      })[0].pe_secretkey;

      var today = new Date();
      var mes = (today.getMonth() + 1).toString();
      var dia = today.getDate().toString();
      console.log("today.getDate()", today.getDate());
      if (today.getMonth() + 1 < 10) mes = "0" + (today.getMonth() + 1);

      if (today.getDate() < 10) {
        dia = "0" + today.getDate();
      }

      var hoy = dia + "/" + mes + "/" + today.getFullYear();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      var preciocm_ = parseFloat(preciocm) * 100;
      var valido = this.nodisponibleDay;
      var eventDates = {};

      this.citamedicaService
        .disponibilidad({ sedeid: this.sedeForm.get("idsede").value })
        .subscribe((data: any) => {
          this.spinner.hide();
          if (data.tokenstatus) {
            this.router.navigate(["/"]);
            this.spinner.hide();
            // $("#modal_cita").addClass('is-visible')
          }

          data.data.forEach(function (value) {
            if (value == "02/21/2022") {
              if (valido) {
                eventDates[new Date(value).toString()] = new Date(value);
              }
            } else {
              eventDates[new Date(value).toString()] = new Date(value);
            }
          });
        });

      var fecha = hoy;
      const param = {
        fecha: fecha,
        idperfil: 3,
        idsede: this.sedeForm.get("idsede").value,
      };
      this.nodisponibleDay = false;

      this.citamedicaService.turnos(param).subscribe((data: any) => {
        const milisegundosactual = new Date().getTime();

        data.forEach((row) => {
          row.horas.forEach((hora) => {
            hora.inicioms = this.utils.formatoMs(fecha, hora.inicio);
            hora.finms = this.utils.formatoMs(fecha, hora.fin);
          });

          const horasValida = row.horas.filter((hora) => {
            return hora.inicioms > milisegundosactual;
          });

          row.horas = horasValida;
        });
        const dataHoras = data.filter((row) => {
          return row.horas.length > 0;
        });

        if (this.turnos.length > 0) {
          this.nodisponibleDay = true;
        } else {
          this.nodisponibleDay = false;
        }
      });

      console.log("nodisponibleDay", this.nodisponibleDay);

      var getAvailableDate = (date) => {
        var highlight = eventDates[date];

        if (highlight) {
          return [true, "ui-available-date", "Consulta disponible"];
        } else {
          return [true, " ui-datepicker-unselectable ui-state-disabled", ""];
        }
      };
      var showDatepickerLegend = () => {
        setTimeout(function () {
          $(".ui-datepicker-buttonpane")
            .html("")
            .append('<p class="ui-datepicker-legend">Horarios Disponibles</p>');
        }, 1);
      };

      $("#datepicker").datepicker({
        minDate: new Date(),
        beforeShowDay: getAvailableDate,
        showOn: "both",
        buttonImage: "./assets/images/cil_calendar.svg",
        buttonImageOnly: true,
        showButtonPanel: true,
        beforeShow: showDatepickerLegend,
        onChangeMonthYear: showDatepickerLegend,
      });

      this.idsede = idsede;

      console.log(this.idsede);
    }
  }

  onChange(deviceValue) {
    $("#datepicker").val("");
    $("#idsede").val("");
    this.turnos.length = 0;
    this.tipoconsulta = deviceValue;
    if (deviceValue == "Presencial") {
      this.loadSede = true;
      $("#selectSede").removeClass("hidden");
      selectsForms();
      // this.sedeForm.get("idsede").setValue(this.sedesList[1].idsede);

      // this.selectSede.nativeElement.click();

      // Espera un tick para que se rendericen las opciones
      // setTimeout(() => {
      //   // Obtiene la segunda opción (índice 1)
      //   const opciones = this.selectSede.nativeElement.options;
      //   if (opciones.length > 1) {
      //     // Dispara el evento change manualmente
      //     this.selectSede.nativeElement.value = opciones[1].value;
      //     this.selectSede.nativeElement.dispatchEvent(new Event("change"));
      //   }
      // });
    } else {
      this.spinner.show();
      $("#grupo-fecha").removeClass("hidden");
      $("#datepicker").datepicker("destroy");
      const idsede = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].idsede;

      const nombresede = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].nombre;

      const preciocm = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].preciocm;

      const usu_izipay = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].usu_izipay;

      const passw_izipay = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].passw_izipay;

      const clave_publica_izipay = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].clave_publica_izipay;

      const pe_idservice = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].pe_idservice;

      const pe_accesskey = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].pe_accesskey;

      const pe_secretkey = this.sedes.filter((row) => {
        return row.idsede === 15;
      })[0].pe_secretkey;

      // var fechasdisponibles = this.sedes.filter(row => {
      //   return row.idsede === 15;
      // })[0].disponibles;

      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      var preciocm_ = parseFloat(preciocm) * 100;

      this.idsede = 15;
      console.log(this.idsede);

      var eventDates = {};

      var fechasdisponibles = [];
      this.citamedicaService.disponibilidad({ sedeid: this.idsede }).subscribe(
        (data: any) => {
          this.spinner.hide();
          if (data.tokenstatus) {
            this.router.navigate(["/"]);
            this.spinner.hide();
          }
          fechasdisponibles.push(data.data);
          fechasdisponibles.forEach(function (value) {
            eventDates[new Date(value).toString()] = new Date(value);
          });
        },
        (error) => {
          console.log("Error");
        }
      );

      var getAvailableDate = (date) => {
        var highlight = eventDates[date];

        if (highlight) {
          return [true, "ui-available-date", "Consulta disponible"];
        } else {
          return [true, " ui-datepicker-unselectable ui-state-disabled", ""];
        }
      };
      var showDatepickerLegend = () => {
        setTimeout(function () {
          $(".ui-datepicker-buttonpane")
            .html("")
            .append('<p class="ui-datepicker-legend">Horarios Disponibles</p>');
        }, 1);
      };

      $("#datepicker").datepicker({
        minDate: new Date(),
        beforeShowDay: getAvailableDate,
        showOn: "both",
        buttonImage: "./assets/images/cil_calendar.svg",
        buttonImageOnly: true,
        showButtonPanel: true,
        beforeShow: showDatepickerLegend,
        onChangeMonthYear: showDatepickerLegend,
      });

      $("#selectSede").addClass("hidden");
      this.loadSede = false;
    }
  }

  onChangeDate(deviceValue) {
    this.indexTurnos(deviceValue);
  }

  createForm(): FormGroup {
    const idsede = null;
    const fechacita = null;
    const hora = null;
    return new FormGroup({
      idsede: new FormControl(idsede, Validators.required),
      fechacita: new FormControl(fechacita, Validators.required),
      hora: new FormControl(hora, Validators.required),
    });
  }

  createFormHorario(): FormGroup {
    return new FormGroup({
      fecha: new FormControl(null, Validators.required),
      hora: new FormControl(null, Validators.required),
    });
  }

  salir(): void {
    this.entidadService.logout().subscribe(
      () => this.router.navigate(["/"]),
      (error) => {
        console.log("Error");
        setTimeout(() => {
          this.errors = "Error";
        }, 5000);
      }
    );
  }

  checkTurnos(): void {
    const allInputs = $(".pick-turn-mod__dates input");
    $(".pick-turn-mod__dates").each(function () {
      $(this)
        .find("button")
        .each(function () {
          $(this).on("click", function (e) {
            e.preventDefault();
            allInputs.attr("checked", false);
            $(this).siblings().attr("checked", true);
          });
        });
    });
  }

  selectHora(turno: string, index: BigInteger): void {
    $("#idnext-" + index).css("display", "none");
    this.turnofin = turno["fin"];
    this.turnoinicio = turno["inicio"];
    this.medico = turno["idmedico"];
    this.sexodoctor = turno["sexo"];

    $("#idnext-" + index).css("display", "block");
    this.horarioForm.get("hora").setValue(turno);
    this.ValidarCitas = true;
  }

  next(): void {
    var correo = "";
    if (this.entidadService.usuario) {
      correo = this.entidadService.usuario.email;
    } else {
      this.router.navigate(["/"]);
      // correo = "mail@mail.com"
    }
    var sedeid = 15;
    if (this.tipoconsulta == "Presencial") {
      sedeid = this.sedeForm.get("idsede").value;
    }

    const nombresede = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].nombre;

    const preciocm = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].preciocm;

    const usu_izipay = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].usu_izipay;

    const passw_izipay = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].passw_izipay;

    const clave_publica_izipay = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].clave_publica_izipay;

    const pe_idservice = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].pe_idservice;

    const pe_accesskey = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].pe_accesskey;

    const pe_secretkey = this.sedes.filter((row) => {
      return row.idsede === sedeid;
    })[0].pe_secretkey;

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    var preciocm_ = parseFloat(preciocm) * 100;
    var aleatorio = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    var parametros = {
      usuario: usu_izipay,
      password: passw_izipay,
      pe_idservice: pe_idservice,
      pe_accesskey: pe_accesskey,
      pe_secretkey: pe_secretkey,
      precio: preciocm_,
      email: correo,
      orderid:
        "pagoReservaCitamedica" +
        "_" +
        this.entidadService.usuario.id +
        "_" +
        "reserva_" +
        aleatorio,
    };
    debugger;
    this.spinner.show();
    this.entidadService.reservacion.idsede = sedeid;
    this.entidadService.reservacion.izipay = parametros;
    this.entidadService.reservacion.fecha = this.fechacita;
    this.entidadService.reservacion.hora = this.turnoinicio;
    this.entidadService.reservacion.sexodoctor = this.sexodoctor;
    this.entidadService.reservacion.nombresede = nombresede;
    this.entidadService.reservacion.preciocm = preciocm;
    this.entidadService.reservacion.llave_publica = clave_publica_izipay;
    this.entidadService.reservacion.fecha = this.horarioForm.get("fecha").value;
    this.entidadService.reservacion.hora = this.horarioForm.get("hora").value;
    this.entidadService.saveReservacion();

    this.entidadService.reservacion.izipay.orderid =
      "pagoReservaCitamedica" +
      "_" +
      this.entidadService.usuario.id +
      "_" +
      "reserva_" +
      aleatorio;

    this.entidadService.reservacion.orderid =
      "pagoReservaCitamedica" +
      "_" +
      this.entidadService.usuario.id +
      "_" +
      "reserva_" +
      aleatorio;

    var citamedica = {
      idsede: this.entidadService.reservacion.idsede,
      idpaciente: this.entidadService.usuario.id,
      fecha: this.entidadService.reservacion.fecha,
      inicio: this.entidadService.reservacion.hora.inicio,
      fin: this.entidadService.reservacion.hora.fin,
      email: this.entidadService.usuario.email,
      horaactual: 1,
      idmedico: this.entidadService.reservacion.hora.idmedico,
      orderid: this.entidadService.reservacion.orderid,
      bloqueo: 1,
      reservatuconsulta: 1,
    };

    this.entidadService.reservacion.temporalcita = citamedica;

    this.citamedicaService
      .CreateTempCitaMedica({ citamedica: citamedica })
      .subscribe((data: any) => {
        console.log("data", data);
        this.spinner.hide();
        if (data.type === "success") {
          this.router.navigate(["/registro"]);
          this.MedioPago = true;
          this.entidadService.reservacion.citalibre = true;
          this.entidadService.saveReservacion();
        } else {
          console.log("mensaje", data.data);
          $("#modal_cita_appoiment").addClass("is-visible");
          this.detallecitas.error = data.data;
          this.spinner.hide();
        }
      });
  }
}
