import { ServiceSitedsBase } from './serviceSitedsBase';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OsitedsService extends ServiceSitedsBase {
    constructor(http) {
        super();
        this.http = http;
    }
    // Metodos
    GetSedesDeIpress() {
        return this.getQuery('api/siteds-obtener-sedes-ipress-osi').pipe(map(data => {
            return data;
        }));
    }
    GetIafasOsiteds() {
        return this.getQuery('api/siteds-obtener-iafas-asociadas-appsiteds').pipe(map(data => {
            return data;
        }));
    }
    GetEntidadVinculada(params) {
        return this.getQuery('api/siteds-getconsulta-entvinculada', params).pipe(map(data => {
            return data;
        }));
    }
    GetCodigoAfiliadoSiteds(params) {
        return this.getQuery('api/siteds-getconsulta-asegnom', params).pipe(map(data => {
            return data;
        }));
    }
    GetDetalleAseguradoSiteds(params) {
        return this.getQuery('api/siteds-getconsulta-asegcod', params).pipe(map(data => {
            return data;
        }));
    }
    GetCondicionesMedicas(params) {
        return this.getQuery('api/siteds-consultar-condiciones-medicas', params).pipe(map(data => {
            return data;
        }));
    }
}
OsitedsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OsitedsService_Factory() { return new OsitedsService(i0.ɵɵinject(i1.HttpClient)); }, token: OsitedsService, providedIn: "root" });
