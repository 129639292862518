<app-header></app-header>
<div class="content">
  <div class="steps-wrapper">
    <div class="message">
      <div class="message__step message__step--active step-message-1">
        <p class="font-light">2 de 4</p>
        <h2 class="text-2xl font-medium mt-5 mb-10">Reserve una cita<br />médica particular</h2>
      </div>

    </div>
    <div class="progress">
      <div class="progress__step step-1-ico progress__step--done"><span class="progess__step-text">Datos
          personales</span></div>
      <div class="progress__step progress__step--active step-2-ico"><span class="progess__step-text">Horarios y
          Doctores</span></div>
      <!-- <div class="progress__step step-3-ico"><span class="progess__step-text">Cuenta OSI</span></div> -->
      <div class="progress__step step-3-ico"><span class="progess__step-text">Cobertura, condiciones y pago</span></div>
      <div class="progress__step step-4-ico"><span class="progess__step-text">Pago y Resumen</span></div>
    </div>
  </div>

  <div class="form-wrapper">
    <form id="form" class="form-reservation" [formGroup]="sedeForm">
      <div id="doctor-schedule" class="form-reservation__section">
        <h3 class="form-reservation__title">Seleccione los datos de su consulta médica:</h3>
        <div class="form-reservation__field-schedule">
          <div id="grupo-consulta" style="display: none;"
            class="form-reservation__field-schedule__group field-group md:flex-auto select-field">
            <label class="label" for="consulta" class="label">Tipo de consulta:</label>
            <select (change)="onChange($event.target.value)" class="field" name="consulta" id="consulta">
              <option *ngFor="let item of tipocitas" [ngValue]="item.idtipocita"> {{item.abreviatura}}</option>
            </select>
          </div>
          <div id="selectSede"
            class="form-reservation__field-schedule__group field-group hidden md:flex-auto select-field">
            <label class="label" for="sede" class="label">Sede:</label>
            <select #selectSede (change)="onChangeSede($event)" formControlName="idsede" id="idsede" class="field">
              <option disabled selected value="">Seleccione</option>
              <option *ngFor="let item of sedesList" [ngValue]="item.idsede">
                {{item.nombre}}</option>
            </select>
          </div>
          <div id="grupo-fecha"
            class="form-reservation__field-schedule__group field-group hidden field-addon md:flex-auto">
            <label class="label" for="datepicker">Fecha:</label>
            <input class="field" type="text" id="datepicker" formControlName="fechacita" name="datepicker"
              placeholder="00/00/2021" />
          </div>
        </div>
        <div class="form-reservation__available-doctors " *ngIf=" turnos.length > 0">
          <p class="text-specialists"><span class="text-blue-1 font-bold" *ngIf=" turnos.length == 1">{{turnos.length}}
              Doctor disponible</span>
            <span class="text-blue-1 font-bold" *ngIf=" turnos.length > 1">{{turnos.length}} Doctores disponibles</span>
            para la fecha seleccionada:
          </p>
          <div class="card" *ngFor="let item of turnos;  let i = index">
            <div class="doctor">

              <img class="doctor__image" src="./assets/images/doctores/{{item.idmedico}}.jpg"
                onError="this.src='./assets/images/doctores/icon_doctor.jpg'" alt="Doctor 1">
              <div class="doctor__content">
                <p class="doctor__fullname">
                  <span class="doctor__name">{{item.sexo == 'F' ? 'Dra.' :' Dr. '}} {{item.nombre}}</span>
                </p>
                <p class="doctor__cmp">
                  <span class="doctor__lastname"> Med. Física y Rehabilitación</span>
                </p>
              </div>
            </div>
            <p class="card__text">Elija un horario:</p>
            <div class="card__schedule">
              <div class="card__schedule-button-group__wrapper">
                <div class="button-group" style="display: grid; grid-template-columns: repeat(3, 1fr);">
                  <div class="doctor" *ngFor="let turno of item.horas; let j = index">
                    <button class="button" (click)="selectHora(turno, i)" type="button" value="">{{turno.inicioms |
                      date:'h:mm a'}}</button>
                  </div>
                </div>
                <div class="card__schedule-button__wrapper" id="idnext-{{i}}" style="display: none;">
                  <input class=" button bg-green-1" (click)="next()" type="button" value="Continuar">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-reservation__available-doctors " *ngIf="nodisponible == true">
          <p class="text-specialists">
            <span class="text-blue-1 font-bold">NO HAY DOCTORES DISPONIBLES</span>
            para la fecha seleccionada:
          </p>
        </div>
      </div>
    </form>
    <br>
    <a (click)="eliminarSession()" href="#" class="text-body font-size-small">
      <u>Volver atrás</u>
    </a>
  </div>
</div>

<div class="modal" id="modal_cita_appoiment" *ngIf="!MedioPago">
  <div class="form-reservation__section" style="text-align: center;">
    <h3 class="form-reservation__title">Alerta</h3>
    <div class="resume__wrapper">
      <div class="resume__section">
        <p>{{detallecitas.error}}</p>
      </div>
    </div>
    <!-- <div class="resume__wrapper">
      <button type="button" style="display: inline;" (click)="closeModalCita()" class="button bg-blue-1">Cerrar</button>
    </div> -->
  </div>
</div>

<div class="overlay_sede" id="overlay"></div>
<div class="modal" id="modal_prox" style="max-width: 250px;">
  <div class="form-reservation__section" style="text-align: center;">
    <h3 class="form-reservation__title"></h3>
    <div class="resume__wrapper">
      <div class="resume__section">
        <p>Próximamente.</p>
      </div>
    </div>
    <div class="resume__wrapper">
      <button type="button" style="display: inline;" (click)="closeModalProx()" class="button bg-blue-1">Cerrar</button>
    </div>
  </div>

</div>
<ngx-spinner></ngx-spinner>
