import { ServiceBase } from './serviceBase';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./utils";
export class EntidadService extends ServiceBase {
    constructor(http, utils) {
        super();
        this.http = http;
        this.utils = utils;
        this.modulos = [];
        this.sedes = [];
        this.reservacion = {
            pagado: null,
            idsede: null,
            pkpago: null,
            nombresede: null,
            preciocm: null,
            fecha: null,
            hora: null,
            user_message: null,
            filenamepdf: null
        };
        this.cargarStorage();
    }
    estaLogueado() {
        this.cargarStorage();
        return (this.token.length > 5) ? true : false;
    }
    authenticate(objeto) {
        return this.postQuery(`osi/authenticate`, objeto).pipe(map(data => {
            if (data.type === 'success') {
                const user = {
                    id: data.data.entidad.identidad,
                    name: data.data.entidad.nombres,
                    idempresa: null,
                    imgperfil: data.data.entidad.imgperfil,
                    apellidos: data.data.entidad.apellidos,
                    apellidopat: data.data.apellidopat,
                    apellidomat: data.data.apellidomat,
                    email: data.data.entidad.email
                };
                const reservacion = {
                    pagado: null,
                    idsede: null,
                    pkpago: null,
                    nombresede: null,
                    preciocm: null,
                    fecha: null,
                    hora: null,
                    user_message: null,
                    filenamepdf: null
                };
                localStorage.setItem('tokenPF', data.data.token);
                localStorage.setItem('usuarioPF', JSON.stringify(user));
                if (!localStorage.getItem('reservacionPF')) {
                    localStorage.setItem('reservacionPF', JSON.stringify(reservacion));
                }
                // localStorage.setItem('reservacionPF', JSON.stringify(reservacion));
                this.cargarStorage();
            }
            return data;
        }));
    }
    checkDocument(objeto) {
        return this.getQuery(`osi/checkdocument`, objeto).pipe(map(data => {
            if (data.type === 'success') {
                const user = {
                    id: data.data.entidad.identidad,
                    name: data.data.entidad.nombres,
                    idempresa: null,
                    imgperfil: data.data.entidad.imgperfil,
                    apellidos: data.data.entidad.apellidos,
                    email: data.data.entidad.email,
                    apellidopat: data.data.apellidopat,
                    apellidomat: data.data.apellidomat,
                };
                const reservacion = {
                    pagado: null,
                    idsede: null,
                    pkpago: null,
                    nombresede: null,
                    preciocm: null,
                    fecha: null,
                    hora: null,
                    user_message: null,
                    filenamepdf: null
                };
                localStorage.setItem('tokenPF', data.data.token);
                localStorage.setItem('usuarioPF', JSON.stringify(user));
                if (!localStorage.getItem('reservacionPF')) {
                    localStorage.setItem('reservacionPF', JSON.stringify(reservacion));
                }
                // localStorage.setItem('reservacionPF', JSON.stringify(reservacion));
                this.cargarStorage();
            }
            return data;
        }));
    }
    cargarStorage() {
        if (localStorage.getItem('tokenPF')) {
            this.token = localStorage.getItem('tokenPF');
            this.usuario = JSON.parse(localStorage.getItem('usuarioPF'));
            // this.settings = JSON.parse(localStorage.getItem('settingsPF'));
            // this.modulos = JSON.parse(localStorage.getItem('modulosPF'));
            // this.sedes = JSON.parse(localStorage.getItem('sedesPF'));
            // this.sedeDefault = parseInt(localStorage.getItem('sedeDefaultPF'), 10);
            this.reservacion = JSON.parse(localStorage.getItem('reservacionPF'));
        }
        else {
            this.token = '';
            this.usuario = null;
            this.reservacion = {
                pagado: null,
                idsede: null,
                pkpago: null,
                nombresede: null,
                preciocm: null,
                fecha: null,
                hora: null,
                user_message: null,
                filenamepdf: null
            };
            // this.settings = null;
            // this.modulos = [];
            // this.sedes = [];
            // this.sedeDefault = null;
        }
    }
    refreshToken(token) {
        localStorage.setItem('tokenPF', token);
        this.token = localStorage.getItem('tokenPF');
    }
    saveReservacion() {
        localStorage.setItem('reservacionPF', JSON.stringify(this.reservacion));
    }
    createPaciente(objeto) {
        return this.postQuery('osi/entidad/paciente', objeto).pipe(map(data => {
            return data;
        }));
    }
    recoveryPassword(objeto) {
        return this.postQuery('osi/entidad/recovery', objeto).pipe(map(data => {
            return data;
        }));
    }
    guardarStorage(id, token, usuario, modulos) {
        // localStorage.setItem('id', id);
        localStorage.setItem('tokenPF', token);
        localStorage.setItem('usuarioPF', JSON.stringify(usuario));
        if (modulos) {
            localStorage.setItem('modulosPF', JSON.stringify(modulos));
        }
        this.token = token;
        this.usuario = usuario;
        if (modulos) {
            this.modulos = modulos;
        }
    }
    logout() {
        return this.postQuery('logout').pipe(map(data => {
            this.token = '';
            this.usuario = null;
            localStorage.removeItem('tokenPF');
            localStorage.removeItem('usuarioPF');
            localStorage.removeItem('reservacionPF');
        }));
    }
    clearStorage() {
        console.log('CLEAR storage y propiedades');
        this.token = '';
        this.usuario = null;
        this.settings = null;
        this.modulos = [];
        this.sedes = [];
        this.sedeDefault = null;
        // localStorage.removeItem('id');
        localStorage.removeItem('tokenPF');
        localStorage.removeItem('usuarioPF');
        localStorage.removeItem('settingsPF');
        localStorage.removeItem('modulosPF');
        localStorage.removeItem('sedesPF');
        localStorage.removeItem('sedeDefaultPF');
    }
    updateImgPerfil(imgperfil) {
        this.usuario.imgperfil = imgperfil;
        console.log(this.usuario.id, this.token, this.usuario);
        this.guardarStorage(this.usuario.id, this.token, this.usuario);
    }
    empresaToken(objeto) {
        return this.postQuery('empresatoken', objeto).pipe(map(data => {
            return data.data;
        }));
    }
    InformacionFormularioQuejas() {
        return this.postQuery('osi/entidad/InformacionFormularioQuejas').pipe(map(data => {
            return data;
        }));
    }
    verify(token) {
        return this.getQuery('users/verify/' + token).pipe(map(data => {
            return data.data;
        }));
    }
    forgot(objeto) {
        return this.postQuery('users/forgot', objeto).pipe(map(data => {
            return data.data;
        }));
    }
    reset(token, objeto) {
        return this.postQuery('users/reset/' + token, objeto).pipe(map(data => {
            return data.data;
        }));
    }
    me(token) {
        return this.getQuery(`users/me/${token}`).pipe(map(data => {
            return data.data;
        }));
    }
    meUpdate(token, objeto) {
        return this.postQuery(`users/me/${token}/update`, objeto).pipe(map(data => {
            return data.data;
        }));
    }
}
EntidadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntidadService_Factory() { return new EntidadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Utils)); }, token: EntidadService, providedIn: "root" });
