
    <app-header></app-header>

    <div class="content">
          <div class="steps-wrapper">
              <div class="message">
                  <div class="message__step message__step--active step-message-1">
                      <p class="font-light">3 de 5</p>
                      <h2 class="text-2xl font-medium mt-5 mb-10">Ya casi termina de reservar<br/>
                        su cita médica particular.</h2>
                  </div>
                  
              </div>
              <div class="progress">
                  <div class="progress__step step-1-ico progress__step--done"><span class="progess__step-text">Datos personales</span></div>
                  <div class="progress__step step-2-ico progress__step--done"><span class="progess__step-text">Horarios y Doctores</span></div>
                  <div class="progress__step progress__step--active step-3-ico"><span class="progess__step-text">Cuenta OSI</span></div>
                  <div class="progress__step step-4-ico"><span class="progess__step-text">Cobertura, condiciones y pago</span></div>
                  <div class="progress__step step-5-ico"><span class="progess__step-text">Resumen</span></div>
              </div>
          </div>
          <div class="form-wrapper">
            <h3 class="form-reservation__title">Vamos a crear su cuenta OSI</h3>
            <form id="form" class="form-reservation__section account-information" [formGroup]="registroForm" (ngSubmit)="save()" novalidate>
              <div class="field-group">
                <label class="label" for="documento">Tipo de Documento:</label>
                <select class="field" name="iddocumento" id="iddocumento" [ngClass]="{ 'is-invalid': submitted && f.iddocumento.errors }"
                formControlName="iddocumento">
                    <option disabled selected>Seleccione</option>
                    <option *ngFor="let item of documentos" [ngValue]="item.iddocumento">
                      {{item.abreviatura}}</option>
                </select>
              </div>
              
              <div class="field-group">
                <label class="label" for="DNI">Número de documento:</label>
                <input class="field" id="DNI" type="text" [ngClass]="{ 'is-invalid': submitted && f.numerodoc.errors }" formControlName="numerodoc" placeholder="" name="dni">
              </div>
              <div class="field-group">
                <label class="label" for="correo" id="correo">Correo electrónico:</label>                            
                <input class="field" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" type="email" placeholder="tucorreo@ejemplo.com" name="correo">
              </div>
              <div class="field-group">
                <label class="label" for="password" id="password">Crea una contraseña:</label>                            
                <input class="field" type="password" name="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password">
              </div>
              <div class="field-group">
                <label class="label" for="password-r" id="password-r">Repita la contraseña:</label>                            
                <input class="field" type="password" name="password-r" [ngClass]="{ 'is-invalid': submitted && f.passwordrepeat.errors }"
                formControlName="passwordrepeat">
              </div>

              <div>
                <button id="button-continue__step-3" class="button bg-green-1 disabled:opacity-50" type="submit" [disabled]="!registroForm.valid" >Continuar</button>
              </div>

              <div class="alert alert-danger animate__animated animate__headShake" role="alert"
                  *ngIf="errors" style="margin-top: 8px">
                  {{errors}}
              </div>

            </form>
            <br>
            <a [routerLink]="'/seleccione-sede'" class="text-body font-size-small">
              <u>Volver atrás</u>
          </a>
          </div>

    <ngx-spinner></ngx-spinner>

</div>
