<app-header></app-header>
<div class="content">
  <div class="steps-wrapper">
    <div class="message">
      <div class="message__step message__step--active step-message-1">
        <p class="font-light">Empecemos</p>
        <h2 class="text-2xl font-medium mt-5 mb-10">Reserve una cita<br />médica particular</h2>
      </div>

    </div>
    <div class="progress">
      <div class="progress__step progress__step--active step-1-ico"><span class="progess__step-text">Datos
          personales</span></div>
      <div class="progress__step step-2-ico"><span class="progess__step-text">Horarios y Doctores</span></div>
      <!-- <div class="progress__step step-3-ico"><span class="progess__step-text">Cuenta OSI</span></div> -->
      <div class="progress__step step-3-ico"><span class="progess__step-text">Cobertura, condiciones y pago</span>
      </div>
      <div class="progress__step step-4-ico"><span class="progess__step-text">Resumen</span></div>
    </div>
  </div>

  <div class="form-wrapper" *ngIf="!formIngreso">
    <form class="form-reservation" [formGroup]="inicioForm" id="home-form" (ngSubmit)="continuarInicio()"
      *ngIf="formInicio" novalidate>
      <div id="personal-information" class="form-reservation__section">
        <h3 class="form-reservation__title">Ingrese los siguientes datos</h3>
        <div class="field-group">
          <label class="label" for="documento">Tipo de Documento:</label>
          <select class="field" name="iddocumento" id="iddocumento" formControlName="iddocumento"
            (change)="onChangeTipoDoc($event.target.value)">
            <option disabled selected>Seleccione</option>
            <option *ngFor="let item of documentos" [ngValue]="item.iddocumento">
              {{item.abreviatura}}</option>
          </select>
        </div>
        <div class="field-group">
          <label class="label" for="DNI">Número de documento:</label>
          <input class="field" (keyup)="onChangeDoc($event.target.value)" formControlName="numerodoc" #username
            type="text">
        </div>

        <div class="field-group" *ngIf="patientExist">
          <label class="label" for="nombre">Nombre:</label>
          <input class="field" id="nombres" formControlName="nombre" [readonly]="patientExistBD" #nombres type="text"
            placeholder="Nombre" name="nombres">
        </div>
        <div class="field-group" *ngIf="patientExist">
          <label class="label" for="apellido">Apellido Paterno:</label>
          <input class="field" id="apellido" type="text" placeholder="apellidopat" [readonly]="patientExistBD"
            name="apellidopat" formControlName="apellidopat" #apellidopat>
        </div>
        <div class="field-group" *ngIf="patientExist">
          <label class="label" for="apellido">Apellido Materno:</label>
          <input class="field" id="apellido" type="text" placeholder="apellidomat" [readonly]="patientExistBD"
            name="apellidomat" formControlName="apellidomat" #apellidomat>
        </div>
        <div class="field-group" *ngIf="patientExist && !patientExistBD">
          <label class="label" for="email" id="email">Correo electrónico:</label>
          <input class="field" formControlName="email" type="email" placeholder="tucorreo@ejemplo.com" name="email"
            #email>
        </div>
        <div class="field-group" *ngIf="patientExist">
          <label class="label" for="celular" id="celular">N° de celular:</label>
          <input class="field" type="text" placeholder="+51" formControlName="celular" name="celular" #celular>
        </div>
        <!-- <div class="field-group" *ngIf="patientExist && !patientExistBD">
                    <label class="label" for="password" id="password">Cree su contraseña:</label>
                    <input class="field" formControlName="password" type="password" placeholder="******" name="password"
                        #password>
                </div> -->

        <!-- <div class="field-group" *ngIf="patientExist && !patientExistBD">
                    <label class="label" for="departamento">Departamento:</label>
                    <select required class="field" (change)="changeUbigeo($event.target.value,'departamentos')"
                        formControlName="dpto" *ngIf="ListDepartamento.length != 0" style="display: block!important">
                        <option *ngFor="let data of ListDepartamento" [ngValue]="data.dpto"> {{data.nombre}}</option>
                    </select>
                </div>
                <div class="field-group" *ngIf="Listprovincias.length != 0">
                    <label class="label" for="provincia">Provincia:</label>
                    <select required class="field" (change)="changeUbigeo($event.target.value,'provincias')"
                        formControlName="prov" *ngIf="Listprovincias.length != 0"
                        style="display: block!important">
                        <option *ngFor="let data of Listprovincias" [ngValue]="data.prov"> {{data.nombre}}</option>

                    </select>
                </div>
                <div class="field-group" *ngIf="ListDistritos.length != 0">
                    <label class="label" for="distrito">Distrito:</label>
                    <select  class="field"  *ngIf="ListDistritos.length != 0" (change)="changeUbigeo($event.target.value,'distrito')"
                    formControlName="dist"
                        style="display: block!important">
                        <option *ngFor="let data of ListDistritos" [ngValue]="data.dist"> {{data.nombre}}</option>
                    </select>
                </div> -->
        <!-- <div class="field-group">
                            <label class="label" for="celular" id="celular">Distrito:</label>
                            <select class="field" [(ngModel)]="selectedseguro" (change)="changeUbigeo($event.target.value,'departamento')">
                            <option value="">-- Distrito --</option>
                            </select>
                        </div> -->
        <!-- <select name="departamento" formControlName="vm.dpto"
                            ng-options="item.dpto as item.nombre for item in vm.others.departamentos"
                            class="field"
                            ng-change="vm.changeUbigeo({pais: vm.pais, dpto: vm.dpto || ''}, 'provincias','normal')">
                            <option value="">-- Departamento --</option>
                          </select> -->
        <!-- <div class="field-group" >
                            <label class="label" for="celular" id="celular">Provincia:</label>
                            <select name="provincia" ng-model="vm.prov"
                            ng-options="item.prov as item.nombre for item in vm.others.provincias" class="field"
                            ng-change="vm.changeUbigeo({pais: vm.pais, dpto: vm.dpto || '', prov: vm.prov || ''}, 'distritos','normal')">
                            <option value="">-- Provincia --</option>
                          </select>
                        </div>
                        <div class="field-group" >
                            <label class="label" for="celular" id="celular">Distrito:</label>
                            <select name="distrito" ng-model="vm.dist"
                            ng-options="item.dist as item.nombre for item in vm.others.distritos" class="field"
                            required>
                            <option value="">-- Distrito --</option>
                          </select>
                        </div> -->
        <!-- <div class="row">

                            <div class="form-group col-sm-4">

                              <label for="numerodoc">Departamento<span class="red">(*)</span></label>
                              <select name="departamento" ng-model="vm.dpto"
                                ng-options="item.dpto as item.nombre for item in vm.others.departamentos"
                                class="form-control input-sm mb-5"
                                ng-change="vm.changeUbigeo({pais: vm.pais, dpto: vm.dpto || ''}, 'provincias','normal')">
                                <option value="">-- Departamento --</option>
                              </select>

                              <div ng-messages="miForm.dpto.$error" class="required" role="alert" ng-show="miForm.dpto.$dirty">
                                <div ng-message="required">El campo es requerido</div>
                              </div>
                            </div>

                            <div class="form-group col-sm-4">
                              <label for="nombre">Provincia <span class="red">(*)</span></label>
                              <select name="provincia" ng-model="vm.prov"
                                ng-options="item.prov as item.nombre for item in vm.others.provincias" class="form-control input-sm mb-5"
                                ng-change="vm.changeUbigeo({pais: vm.pais, dpto: vm.dpto || '', prov: vm.prov || ''}, 'distritos','normal')">
                                <option value="">-- Provincia --</option>
                              </select>
                              <div ng-messages="miForm.prov.$error" class="required" role="alert" ng-show="miForm.prov.$dirty">
                                <div ng-message="required">El campo es requerido</div>
                              </div>
                            </div>

                            <div class="form-group col-sm-4">
                              <label for="numerodoc">Distrito <span class="red">(*)</span></label>
                              <select name="distrito" ng-model="vm.dist"
                                ng-options="item.dist as item.nombre for item in vm.others.distritos" class="form-control input-sm mb-5"
                                required>
                                <option value="">-- Distrito --</option>
                              </select>
                              <div ng-messages="miForm.dist.$error" class="required" role="alert" ng-show="miForm.dist.$dirty">
                                <div ng-message="required">El campo es requerido</div>
                              </div>
                            </div>

                          </div>  -->

        <div class="field-group-checkbox">
          <input class="field-group-checkbox__field" type="checkbox" checked formControlName="policy" name="policy"
            id="policy">
          <label class="field-group-checkbox__label" for="policy"> Estoy de acuerdo con las <a target="_blank"
              href="https://www.centromedicoosi.com/terminos-y-condiciones" class="underline">políticas de
              privacidad</a>.</label>
        </div>

        <div class="alert alert-danger animate__animated animate__headShake" role="alert" *ngIf="errors"
          style="margin-top: 8px;background-color: brown;font-size: 16px;color: white;padding: 15px;text-align: center;">
          {{errors}}
        </div>
        <div>
          <button type="submit" class="button bg-green-1 disabled:opacity-50" value="Continuar"
            [disabled]="!inicioForm.valid">Continuar</button>
          <!-- <button type="submit" class="button bg-blue-1 disabled:opacity-50" [disabled]="!inicioForm.valid" (click)="checkClick()">buscar</button> -->
        </div>
      </div>
    </form>
  </div>
  <!-- <div class="form-wrapper"  *ngIf="formIngreso">
            <form [formGroup]="loginForm" (ngSubmit)="authenticar()" novalidate>
                <h3 class="form-reservation__title">Iniciar Sesión</h3>
                <div class="field-group">
                    <label class="label" for="documento">Tipo de Documento:</label>
                    <select class="field" name="iddocumentolog" id="iddocumentolog"
                    formControlName="iddocumentolog">
                        <option disabled selected>Seleccione</option>
                        <option *ngFor="let item of documentos" [ngValue]="item.iddocumento">
                            {{item.abreviatura}}</option>
                    </select>
                </div>
                <div class="field-group">
                    <label class="label" for="DNI">Número de documento:</label>
                    <input class="field" formControlName="username" #username type="text" >
                </div>
                <div class="field-group">
                    <label class="label" for="password" id="password">Ingresa tu contraseña:</label>
                    <input class="field" type="password" formControlName="password" name="password">
                </div>
                <div>
                    <button id="button-continue__step-3" class="button bg-green-1 disabled:opacity-50" type="submit" [disabled]="!loginForm.valid" >Iniciar Sesión</button>
                </div>

                <div class="alert alert-danger animate__animated animate__headShake" role="alert"
                    *ngIf="errors" style="margin-top: 8px">
                    {{errors}}
                </div>

            </form>
            <button (click)="volverInicio()"class="button  bg-blue-1">
                Volver
            </button>

        </div> -->
  <div class="modal" id="modal_cita" *ngIf="!MedioPago">
    <div class="form-reservation__section" style="text-align: center;">
      <h3 class="form-reservation__title">Alerta</h3>
      <div class="resume__wrapper">
        <div class="resume__section">
          <p>{{detallecitas.error}}, para poder ayudarlo comuníquese con nuestra central telefónica. (01) 7390888</p>
        </div>
      </div>
      <div class="resume__wrapper">
        <button type="button" style="display: inline;" (click)="closeModalCita()"
          class="button bg-blue-1">Cerrar</button>
      </div>
    </div>

  </div>
  <ngx-spinner></ngx-spinner>
</div>
