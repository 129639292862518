import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { EntidadService } from "src/app/services/entidad.service";
import { UbigeoService } from "src/app/services/ubigeo.service";
import { Router, NavigationExtras } from "@angular/router";
import { Utils } from "src/app/services/utils";
import { NgxSpinnerService } from "ngx-spinner";
declare function selectsForms();
import { CitamedicaService } from "src/app/services/citamedica.service";
import {
  FormatTimePipe,
  HeaderComponent,
} from "../../shared/header/header.component";
import { SeguroComponent } from "../seguro/seguro.component";
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styles: [],
})
export class HomeComponent implements OnInit {
  @ViewChild("numerodoc", { static: false }) numeroElement: ElementRef;
  @ViewChild("username", { static: false }) usernameElement: ElementRef;

  formInicio = true;
  formIngreso = false;
  formRecuperar = false;
  errors: string = null;
  respuesta: string = null;
  patientExist = false;
  patientExistBD = false;
  valueTemp: string = null;
  detallecitas: any = [];
  others: any = [];
  dist: any;
  prov: any;
  dpto: any;
  MedioPago = false;
  CostoCero = true;

  citalibre = true;
  ListDepartamento: any[] = [];
  Listprovincias: any[] = [];
  ListDistritos: any[] = [];
  constructor(
    private entidadService: EntidadService,
    private UbigeoService: UbigeoService,
    private router: Router,
    private utils: Utils,
    private spinner: NgxSpinnerService,
    private citamedicaService: CitamedicaService
  ) {
    selectsForms();
  }

  // Formulario
  inicioForm: FormGroup;
  loginForm: FormGroup;
  HeaderComponent: HeaderComponent;
  // Data inicial y listados
  documentos = [
    { iddocumento: 1, abreviatura: "DNI" },
    { iddocumento: 3, abreviatura: "CARNET EXT." },
    { iddocumento: 4, abreviatura: "PASAPORTE Y OTROS" },
  ];
  submitted = false;

  indexProvincia(): void {
    this.spinner.show();

    this.entidadService.InformacionFormularioQuejas().subscribe((data: any) => {
      debugger;

      this.ListDepartamento = data.others.departamentos;
      this.spinner.hide();
    });
  }

  eliminarSession() {
    const datosPaciente = JSON.parse(sessionStorage.getItem("data"));
    if (datosPaciente) {
      this.citamedicaService
        .destroyCitasTemporales({ id: datosPaciente.entidad.identidad })
        .subscribe((data: any) => {
          sessionStorage.clear();
          localStorage.clear();
          HeaderComponent.time_status_ = false;
        });
    }
  }
  ngOnInit() {
    this.indexProvincia();

    this.entidadService.clearStorage();
    this.valueTemp = null;
    this.inicioForm = new FormGroup({
      iddocumento: new FormControl(1, [Validators.required]),
      numerodoc: new FormControl("", [
        Validators.minLength(8),
        Validators.maxLength(8),
        Validators.pattern("^[0-9]*$"),
        Validators.required,
      ]),
      policy: new FormControl(true, [Validators.requiredTrue]),
      navegador: new FormControl(navigator.userAgent),
    });

    this.loginForm = new FormGroup({
      iddocumento: new FormControl(1, [Validators.required]),
      username: new FormControl("", [Validators.required]),
      // password: new FormControl('', [Validators.required]),
      navegador: new FormControl(navigator.userAgent),
    });

    setTimeout(() => {
      selectsForms();
      if (!SeguroComponent.CostoCero) {
        this.CostoCero = false;
        $("#modal_cita_costo_cero").addClass("is-visible");
        $("#overlay").addClass("is-visible");
      }
      this.spinner.hide();
    }, 500);
  }

  focusNumerodoc(): void {
    this.loginForm.get("username").setValue("");
    // this.loginForm.get('password').setValue('');
    this.formIngreso = false;
    this.formRecuperar = true;

    setTimeout(() => {
      this.numeroElement.nativeElement.focus();
    }, 100);
  }

  volverInicio(): void {
    this.spinner.show();
    this.formIngreso = false;
    setTimeout(() => {
      selectsForms();
      this.spinner.hide();
    }, 1000);
  }

  loginClick(): void {
    this.spinner.show();
    this.formIngreso = true;
    setTimeout(() => {
      selectsForms();
      this.spinner.hide();
    }, 1000);
  }

  changeUbigeo(ubigeo, to): void {
    debugger;
    this.others = [];
    if (ubigeo === "") {
      this.others[to] = [];
      return;
    }

    if (to === "departamentos") {
      this.others.dpto = this.inicioForm.get("dpto").value; //Limpiar distritos
      this.others.pais = "PE";
      this.Listprovincias = [];
      this.ListDistritos = [];

      this.spinner.show();
      this.UbigeoService.GetIndex(this.others).subscribe((data: any) => {
        this.Listprovincias = data.data;
        this.spinner.hide();
      });
    }
    if (to === "provincias") {
      this.others.prov = this.inicioForm.get("prov").value; //Por default '- Provincia -'
      this.others.dpto = this.inicioForm.get("dpto").value; //Limpiar distritos
      this.others.pais = "PE";
      this.ListDistritos = [];
      // this.others.distritos = []; //Limpiar distritos
      this.spinner.show();

      this.UbigeoService.GetIndex(this.others).subscribe((data: any) => {
        this.ListDistritos = data.data;
        this.spinner.hide();
      });
    }
    if (to === "distrito") {
      this.submitted = false;
      return;
    }
  }

  onChangeTipoDoc(searchValue): void {
    debugger;
    if (searchValue) {
      sessionStorage.clear(); //Limpiamos storage
      localStorage.clear();
      this.entidadService.clearStorage();
      searchValue = searchValue.substring(0, 1);

      if (searchValue == "1") {
        this.patientExist = false;
        this.patientExistBD = false;
        this.valueTemp = null;
        this.inicioForm = new FormGroup({
          iddocumento: new FormControl(1, [Validators.required]),
          numerodoc: new FormControl("", [
            Validators.minLength(8),
            Validators.maxLength(8),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ]),
          policy: new FormControl(true, [Validators.requiredTrue]),
          navegador: new FormControl(navigator.userAgent),
          // password: new FormControl(true, [Validators.requiredTrue])
        });
      } else if (searchValue == "2") {
        this.patientExist = false;
        this.patientExistBD = false;
        this.valueTemp = null;
        this.inicioForm = new FormGroup({
          iddocumento: new FormControl(2, [Validators.required]),
          numerodoc: new FormControl("", [
            Validators.minLength(9),
            Validators.maxLength(12),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ]),
          policy: new FormControl(true, [Validators.requiredTrue]),
          navegador: new FormControl(navigator.userAgent),
          // password: new FormControl(true, [Validators.requiredTrue])
        });
      } else if (searchValue == "3") {
        this.patientExist = false;
        this.patientExistBD = false;
        this.valueTemp = null;
        this.inicioForm = new FormGroup({
          iddocumento: new FormControl(3, [Validators.required]),
          numerodoc: new FormControl("", [
            Validators.minLength(12),
            Validators.maxLength(20),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ]),
          policy: new FormControl(true, [Validators.requiredTrue]),
          navegador: new FormControl(navigator.userAgent),
          // password: new FormControl(true, [Validators.requiredTrue])
        });
      }
    }
  }

  onChangeDoc(searchValue: string): void {
    if (this.valueTemp) {
      if (this.valueTemp != searchValue) {
        this.patientExist = false;
        this.patientExistBD = false;
        sessionStorage.clear(); //Limpiamos storage
        localStorage.clear();
        this.entidadService.clearStorage();
        this.valueTemp = null;
        this.inicioForm = new FormGroup({
          iddocumento: new FormControl(1, [Validators.required]),
          numerodoc: new FormControl("", [
            Validators.minLength(8),
            Validators.maxLength(12),
            Validators.pattern("^[0-9]*$"),
            Validators.required,
          ]),
          policy: new FormControl(true, [Validators.requiredTrue]),

          navegador: new FormControl(navigator.userAgent),
        });
      }
    }
  }

  authenticar(param): void {
    param.username = param.numerodoc;
    param.password = param.password;
    this.spinner.show();
    this.entidadService.authenticate(param).subscribe(
      (data) => {
        if (data.type === "success") {
          console.log(data);
          sessionStorage.setItem("data", JSON.stringify(data.data));
          this.router.navigate(["/seleccione-sede"]);
        } else {
          this.errors = data.data;
          this.spinner.hide();
          setTimeout(() => {
            this.errors = null;
          }, 5000);
        }
      },
      (error) => {
        this.errors = "Error";
      }
    );
  }

  closeModalCita() {
    $("#modal_cita").removeClass("is-visible");
    $("#overlay").removeClass("is-visible");
  }

  continuarInicio(): void {
    let param: any;
    param = this.inicioForm.getRawValue();
    param.consultasmedicas = true;

    this.spinner.show();
    if (this.patientExist && !this.patientExistBD) {
      var check: boolean = true;
      if (!param["nombre"]) {
        check = false;
      } else if (!param["apellidopat"]) {
        check = false;
      } else if (!param["celular"]) {
        check = false;
      }
      if (check) {
        // sessionStorage.setItem("Invitado",JSON.stringify(param));
        // this.spinner.show();
        // this.router.navigate(['/seleccione-sede']);
        this.submitted = true;

        if (this.inicioForm.invalid) {
          return;
        }
        let param: any;
        param = this.inicioForm.getRawValue();
        this.valueTemp = param.numerodoc;
        param.password = param.numerodoc;
        this.spinner.show();
        this.entidadService.createPaciente({ entidad: param }).subscribe(
          (data) => {
            this.submitted = false;
            this.spinner.hide();
            let parametro: any = this.inicioForm.getRawValue();
            parametro.password = param.numerodoc;
            console.log(data);
            if (data.type === "success") {
              this.authenticar(parametro);
            } else {
              this.errors = data.data.message;
              setTimeout(() => {
                this.errors = null;
              }, 5000);
            }
          },
          (error) => {
            this.submitted = false;
            this.errors = "Error";
            this.spinner.hide();
            setTimeout(() => {
              this.errors = null;
            }, 5000);
          }
        );
      } else {
        this.spinner.hide();
        this.errors = "Complete los campos";
      }
    } else {
      if (this.patientExistBD) {
        this.authenticar(param);
      } else {
        this.valueTemp = param.numerodoc;
        this.entidadService.checkDocument(param).subscribe(
          (data) => {
            if (data.type === "success") {
              //Validar si ya tiene citamedica
              if (data.data.citasMedicas.length > 0) {
                this.MedioPago = false;
                this.spinner.hide();
                $("#modal_cita").addClass("is-visible");
                $("#overlay").addClass("is-visible");
                this.detallecitas.error = "Ya cuenta con una cita existente.";
                debugger;
                this.eliminarSession();
                return false;
              }
              this.patientExist = true;
              this.patientExistBD = true;

              setTimeout(() => {
                this.spinner.hide();
              }, 2000);
              this.inicioForm = new FormGroup({
                iddocumento: new FormControl(param.iddocumento, [
                  Validators.required,
                ]),
                numerodoc: new FormControl(param.numerodoc, [
                  Validators.minLength(8),
                  Validators.maxLength(12),
                  Validators.pattern("^[0-9]*$"),
                  Validators.required,
                ]),
                nombre: new FormControl(data.data.entidad.nombres, [
                  Validators.required,
                ]),
                apellidopat: new FormControl(data.data.entidad.apellidopat, [
                  Validators.required,
                ]),
                apellidomat: new FormControl(data.data.entidad.apellidomat, [
                  Validators.required,
                ]),
                celular: new FormControl(data.data.entidad.celular, [
                  Validators.minLength(9),
                  Validators.maxLength(9),
                  Validators.pattern("^[0-9]*$"),
                  Validators.required,
                ]),
                policy: new FormControl(true, [Validators.requiredTrue]),
                navegador: new FormControl(navigator.userAgent),
                // password: new FormControl(data.data.entidad.password, [Validators.required]),
              });
              sessionStorage.setItem("data", JSON.stringify(data.data));
              this.router.navigate(["/seleccione-sede"]);
            } else {
              this.inicioForm = new FormGroup({
                iddocumento: new FormControl(param.iddocumento, [
                  Validators.required,
                ]),
                numerodoc: new FormControl(param.numerodoc, [
                  Validators.minLength(8),
                  Validators.maxLength(12),
                  Validators.pattern("^[0-9]*$"),
                  Validators.required,
                ]),
                nombre: new FormControl("", [Validators.required]),
                apellidopat: new FormControl("", [Validators.required]),
                apellidomat: new FormControl("", [Validators.required]),
                celular: new FormControl("", [
                  Validators.minLength(9),
                  Validators.maxLength(9),
                  Validators.pattern("^[0-9]*$"),
                  Validators.required,
                ]),
                email: new FormControl(null, [
                  Validators.required,
                  Validators.email,
                ]),
                policy: new FormControl(true, [Validators.requiredTrue]),
                navegador: new FormControl(navigator.userAgent),
                // password: new FormControl('', [Validators.required])
                // dpto: new FormControl('', [Validators.required]),
                // prov: new FormControl('', [Validators.required]),
                // dist: new FormControl('', [Validators.required]),
                // pais: new FormControl('PE', [Validators.required])
              });
              this.spinner.hide();

              //this.errors = data.data;
              this.patientExist = true;
              this.patientExistBD = false;

              setTimeout(() => {
                this.errors = null;
              }, 2000);
            }
          },
          (error) => {
            this.errors = "Error";
          }
        );
      }
    }

    // var check: boolean = true;
    // if(!param['nombres']){
    //   check = false;
    // }
    // else if(!param['apellidopat']){
    //   check = false;
    // }
    // else if(!param['celular']){
    //   check = false;
    // }
    // if(check){
    //   sessionStorage.setItem("Invitado",JSON.stringify(param));
    //   this.spinner.show();
    //   this.router.navigate(['/seleccione-sede']);
    // }
    // else{
    //   this.errors = "Complete los campos";
    // }
  }
}
